import React, { useRef, useEffect } from 'react';
import Quill from 'quill';

import 'quill/dist/quill.snow.css';

const TeacherQuill = () => {
  const quillRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) {
      new Quill(quillRef.current, {
        theme: 'snow'
      });
    }
  }, []);

  return (
    <div className="teste">
      <div ref={quillRef} />
    </div>
  );
};

export default TeacherQuill;