import styled from 'styled-components'

export const Content = styled.div`
.titles {
  color: "var(--white)";
  text-Align: "center";
  
  @media(max-width: 1280){
    margin: "10px";
  }
`

export const Container = styled.div`
margin: 0% 1% 1% 1%;
padding: 1rem;
background: var(--white);
border-bottom-left-radius: 0.25rem;
border-bottom-right-radius: 0.25rem;display: flex;
max-width: 98%;    
flex-wrap: wrap;

h3 {
    display: block;
    width: 100%;
}
`

interface Props { 
    active: boolean; 
}

export const Accordeon = styled.button<Props>`
  background-color: var(--background);
  color: var(--blue-dark);
  cursor: pointer;
  width: 100%;
  max-width: 100%;    
  border: none;
  padding: 0.5%;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    filter: brightness(1)
  }

.panel {
  display: none;
  background-color: var(--background);
  overflow: hidden;
  ${props => props.active && `display: inline-block !important;`};
  }
}`

