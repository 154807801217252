import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function CorrectionHistory(props) {
	const classes = useStyles();
	const [selected, setSelected] = useState(false);
	const [id, setId] = useState(0);
	const [score, setScore] = useState(0);

	function handleChange(id, score) {
		if (selected === true) {
			setSelected(false);
			props.handleChange(props.e.id, id, -score == 0 ? -0.00001 : -score);
		} else {
			setId(id);
			setScore(score);
			setSelected(true);
			props.handleChange(props.e.id, id, score);
		}
	}

	return (
		<div style={{ width: "100%" }}>
			{props.e.options.map((o) => (
				<div
					key={o.descriptorId + "_" + o.id}
					id={o.id}
					className={classes.container}
					style={{
						borderColor:
							(selected === true && id === o.id) || o.score === props.e.score
								? "#f50057"
								: "#e8e8e8",
						backgroundColor:
							(selected === true && id === o.id) || o.score === props.e.score
								? "#f5d3df"
								: "#e8e8e8",
					}}
					onClick={(e) => handleChange(o.id, o.score)}
					hidden={selected === true && id !== o.id ? true : false}
				>
					<p style={{ color: "black" }}>
						<b>Nota: {o.score}</b> — {o.description}
					</p>
				</div>
			))}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	container: {
		border: "1px solid lightgray",
		borderRadius: 10,
		margin: 5,
		cursor: "pointer",
		padding: "0 10px 0 10px",
		"&:hover": {
			filter: "brightness(0.9)",
		},
	},
}));
