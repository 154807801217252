import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import logoImg from "../../assets/logo.png";
import logoImgHomolog from "../../assets/logoHml.png";
import dropdownIcon from "../../assets/dropdownIcon.png";
import logoutIcon from "../../assets/logoutIcon.png";
import diagnosticIcon from "../../assets/diagnosticIcon.png";
import homeIcon from "../../assets/homeIcon.png";
import infoIcon from "../../assets/infoIcon.png";
import rulesIcon from "../../assets/rulesIcon.png";
import tokenIcon from "../../assets/tokenIcon.png";
import trainingIcon from "../../assets/trainingIcon.png";
import proposeIcon from "../../assets/proposeIcon.png";
import userAvatar from "../../assets/user.png";
import InfoIcon from "@mui/icons-material/Info";
import { Container, ToggleButton } from "./styles";
import dotenv from "dotenv";
import { LogoutGoogle, LogoutRedacao } from "../../_Infra/requests";

interface Props {
  profile: string;
  menu: string;
}
export function Topbar(props: Props) {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [isStaging, setIsStaging] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const mail = localStorage.getItem("Email");
    if (mail) setEmail(mail);
  }, []);

  useEffect(() => {
    dotenv.config({ path: ".env.staging" });
    setIsStaging(
      process.env.REACT_APP_API_URL === "https://redacao-api-homolog.pr.gov.br/"
    );
  }, []);

  function handleClick() {
    setOpen(!open);
  }

  const logout = () => {
    LogoutGoogle(email);
    LogoutRedacao();
    history.push("/logout");
  };

  return (
    <Container
      active={open}
      style={{ backgroundColor: isStaging ? "#12AA33" : "var(--blue-light)" }}
    >
      <ToggleButton onClick={handleClick}>
        <span className={"bar"}></span>
        <span className={"bar"}></span>
        <span className={"bar"}></span>
      </ToggleButton>
      <img
        id="logo"
        src={isStaging ? logoImgHomolog : logoImg}
        onClick={() => history.push("/home")}
        alt={"Redação"}
        width={"200rem"}
      />

      {/* MOBILE */}
      {!open && (
        <div style={{ marginRight: "1%" }}>
          {(() => {
            switch (props.profile) {
              case "8": // STUDENT
                return <>{GetGlobalButtons(open)}</>;
              case "10": // TEACHER
                return <>{GetGlobalButtons(open)}</>;
              case "11": // ADMINISTRATOR
                return <>{GetGlobalButtons(open)}</>;
              default:
                break;
            }
          })()}
        </div>
      )}
      {open && (
        <div style={{ marginRight: "1%" }}>
          {(() => {
            console.log("click");
            switch (props.profile) {
              case "8": // STUDENT
                return (
                  <>
                    {GetGlobalButtons(open)}

                    <button
                      {...(props.menu === "home")}
                      onClick={() => history.push("/student/home")}
                      className={"Button"}
                    >
                      <img
                        alt="VOLTAR AO INÍCIO"
                        className={"ddIconItem"}
                        src={homeIcon}
                      />
                      VOLTAR AO INÍCIO
                    </button>

                    <button
                      {...(props.menu === "rules")}
                      onClick={() => history.push("/student/CorrectionRules")}
                      className={"Button"}
                    >
                      <img
                        alt="INSTRUÇÕES"
                        className={"ddIconItem"}
                        src={rulesIcon}
                      />
                      INSTRUÇÕES
                    </button>

                    <button
                      {...(props.menu === "train")}
                      onClick={() => history.push("/student/essay/new")}
                      className={"Button"}
                    >
                      <img
                        alt="TREINAR REDAÇÃO"
                        className={"ddIconItem"}
                        src={trainingIcon}
                      />
                      TREINAR REDAÇÃO
                    </button>
                    <button
                      {...(props.menu === "rules")}
                      onClick={() => history.push("/student/SearchByToken")}
                      className={"Button"}
                    >
                      <img
                        alt="BUSCAR POR TOKEN"
                        className={"ddIconItem"}
                        src={tokenIcon}
                      />
                      BUSCAR POR TOKEN
                    </button>
                    <button
                      {...(props.menu === "info")}
                      onClick={() => history.push("/About/Info")}
                      className={"Button"}
                    >
                      <img
                        alt="INFORMAÇÕES"
                        className={"ddIconItem"}
                        src={infoIcon}
                      />
                      INFORMAÇÕES
                    </button>
                    <button onClick={logout} className={"Button"}>
                      <img
                        alt="SAIR"
                        className={"ddIconItem"}
                        src={logoutIcon}
                      />
                      SAIR
                    </button>
                  </>
                );
              case "10": // TEACHER
                return (
                  <>
                    {GetGlobalButtons(open)}
                    <button
                      {...(props.menu === "home")}
                      onClick={() => history.push("/teacher/home")}
                      className={"Button"}
                    >
                      <img
                        alt="VOLTAR AO INÍCIO"
                        className={"ddIconItem"}
                        src={homeIcon}
                      />
                      VOLTAR AO INÍCIO
                    </button>
                    {/* <button
                      {...(props.menu === "home")}
                      onClick={() => history.push("/teacher/diagnostic/Diagnostic")}
                      className={"Button"}
                    >
                      <img
                        alt="DIAGNÓSTICO"
                        className={"ddIconItem"}
                        src={diagnosticIcon}
                      />
                      DIAGNÓSTICO
                    </button> */}
                    <button
                      {...(props.menu === "train")}
                      onClick={() => history.push("/teacher/essay/new")}
                      className={"Button"}
                    >
                      <img
                        alt="PROPOR REDAÇÃO"
                        className={"ddIconItem"}
                        src={proposeIcon}
                      />
                      PROPOR REDAÇÃO
                    </button>
                    <button
                      {...(props.menu === "train")}
                      onClick={() => history.push("/teacher/PracticeEssay")}
                      className={"Button"}
                    >
                      <img
                        alt="TREINAR REDAÇÃO"
                        className={"ddIconItem"}
                        src={trainingIcon}
                      />
                      TREINAR REDAÇÃO
                    </button>
                    <button
                      {...(props.menu === "info")}
                      onClick={() => history.push("/About/Info")}
                      className={"Button"}
                    >
                      <img
                        alt="INFORMAÇÕES"
                        className={"ddIconItem"}
                        src={infoIcon}
                      />
                      INFORMAÇÕES
                    </button>
                    <button onClick={logout} className={"Button"}>
                      <img
                        alt="SAIR"
                        className={"ddIconItem"}
                        src={logoutIcon}
                      />
                      SAIR
                    </button>
                  </>
                );
              case "11": // ADMINISTRATOR
                return (
                  <>
                    <button
                      style={{ display: "none" }}
                      {...(props.menu === "home")}
                      onClick={() => history.push("/administrator/home")}
                      className={"Button"}
                    >
                      <img
                        alt="VOLTAR AO INÍCIO"
                        className={"ddIconItem"}
                        src={homeIcon}
                      />
                      VOLTAR AO INÍCIO
                    </button>
                    {/* <button
                      {...(props.menu === "home")}
                      onClick={() => history.push("/teacher/diagnostic/Diagnostic")}
                      className={"Button"}
                    >
                      <img
                        alt="DIAGNÓSTICO"
                        className={"ddIconItem"}
                        src={diagnosticIcon}
                      />
                      DIAGNÓSTICO
                    </button> */}
                    <button
                      {...(props.menu === "info")}
                      onClick={() => history.push("/About/Info")}
                      className={"Button"}
                    >
                      <img
                        alt="INFORMAÇÕES"
                        className={"ddIconItem"}
                        src={trainingIcon}
                      />
                      INFORMAÇÕES
                    </button>
                    <button
                      // onClick={() => history.push("/logout")}
                      onClick={logout}
                      className={"Button"}
                    >
                      <img
                        alt="SAIR"
                        className={"ddIconItem"}
                        src={logoutIcon}
                      />
                      SAIR
                    </button>

                    {GetGlobalButtons(open)}
                  </>
                );
              default:
                break;
            }
          })()}
        </div>
      )}
      {/* {!isStaging && (
          <h1 className="txtHml">HOMOLOGAÇÃO</h1>
      )} */}
    </Container>
  );
}

Topbar.propTypes = {
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  ),
};

function GetGlobalButtons(props: boolean) {
  let history = useHistory();
  const [profileDescription, setProfileDescription] = React.useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [isProfessor, setIsProfessor] = useState(false);
  const [pathInicio, setPathInicio] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [userName, setUserName] = React.useState("");
  const firstLetter = userName ? userName.charAt(0).toUpperCase() : "";
  const [email, setEmail] = useState("");

  useEffect(() => {
    const mail = localStorage.getItem("Email");
    if (mail) setEmail(mail);
  }, []);

  useEffect(() => {
    switch (localStorage.getItem("ProfileId")) {
      case "11":
        setProfileDescription("Administrador");
        setPathInicio("/administrator/home");
        break;
      case "10":
        setProfileDescription("Docente");
        setPathInicio("/teacher/home");
        setIsProfessor(true);
        break;
      case "8":
        setProfileDescription("Estudante");
        setPathInicio("/student/home");
        setIsStudent(true);
        break;
    }
    if (window.innerWidth < 900) {
      setIsMobile(true);
      //console.log("wInnerWidth", window.innerWidth);
    }
    //console.log("Mobile?", isMobile);
  }, []);

  React.useEffect(() => {
    const storedUserName = localStorage.getItem("Name");
    if (storedUserName) {
      const nameParts = storedUserName.split(" ");
      setUserName(nameParts[0]);
    }
  }, []);

  const logout = () => {
    LogoutGoogle(email);
    LogoutRedacao();
    history.push("/logout");
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <Avatar
        className={"avatarImg1"}
        sx={{
          background: "#8338ec",
          border: "2px solid #fff",
          width: "3rem",
        }}
        alt={firstLetter}
        src={firstLetter}
      />
      {/* <img
        alt=""
        className={"avatarImg1"}
        // src={localStorage.getItem("Picture") ?? userAvatar}
        src={userAvatar}
      /> */}

      <div
        className={"ddName"}
        style={
          !props
            ? { display: "flex", flexDirection: "row" }
            : { display: "flex", flexDirection: "column" }
        }
      >
        <img alt="" className={"ddIcon"} src={dropdownIcon} />
        <span id="profile">
          <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
            {localStorage.getItem("Name")?.toUpperCase()}
          </p>
          <p>{profileDescription}</p>
        </span>

        {/* <img
          alt=""
          className={"avatarImg"}
          // src={localStorage.getItem("Picture") ?? userAvatar}
          src={userAvatar}
        /> */}
        <Avatar
          className={"avatarImg"}
          sx={{
            background: "#8338ec",
            border: "2px solid #fff",
            width: "3rem",
          }}
          alt={firstLetter}
          src={firstLetter}
        />
      </div>
      {/* DESKTOP */}
      {showDropdown && !isMobile && (
        <div className="dropdownMenu">
          <div
            className={"dropdownItem"}
            onClick={() => history.push(pathInicio)}
          >
            <img
              alt="VOLTAR AO INÍCIO"
              className={"ddIconItem"}
              src={homeIcon}
            />
            <p>VOLTAR AO INÍCIO</p>
          </div>
          <hr />
          {isProfessor && (
            <>
              {/* <div
                className={"dropdownItem"}
                onClick={() => history.push("/teacher/diagnostic/Diagnostic")}
              >
                <img
                  alt="DIAGNÓSTICO"
                  className={"ddIconItem"}
                  src={diagnosticIcon}
                />
                <p>DIAGNÓSTICO</p>
              </div> */}
              <div
                className={"dropdownItem"}
                onClick={() => history.push("/teacher/essay/new")}
              >
                <img
                  alt="PROPOR REDAÇÃO"
                  className={"ddIconItem"}
                  src={proposeIcon}
                />
                <p>PROPOR REDAÇÃO</p>
              </div>
              <hr />
              <div
                className={"dropdownItem"}
                onClick={() => history.push("/teacher/PracticeEssay")}
              >
                <img
                  alt="TREINAR REDAÇÃO"
                  className={"ddIconItem"}
                  src={trainingIcon}
                />
                <p>TREINAR REDAÇÃO</p>
              </div>
              <hr />
            </>
          )}
          {isStudent && (
            <>
              <div
                className={"dropdownItem"}
                onClick={() => history.push("/student/essay/new")}
              >
                <img
                  alt="TREINAR REDAÇÃO"
                  className={"ddIconItem"}
                  src={trainingIcon}
                />
                <p>TREINAR REDAÇÃO</p>
              </div>
              <hr />
              <div
                className={"dropdownItem"}
                onClick={() => history.push("/student/CorrectionRules")}
              >
                <img
                  alt="INSTRUÇÕES"
                  className={"ddIconItem"}
                  src={rulesIcon}
                />
                <p>INSTRUÇÕES</p>
              </div>
              <hr />
              <div
                className={"dropdownItem"}
                onClick={() => history.push("/student/SearchByToken")}
              >
                <img
                  alt="BUSCAR POR TOKEN"
                  className={"ddIconItem"}
                  src={tokenIcon}
                />
                <p>BUSCAR POR TOKEN</p>
              </div>
              <hr />
            </>
          )}
          <div
            className={"dropdownItem"}
            onClick={() => history.push("/About/Info")}
          >
            <img
              alt="SOBRE O REDAÇÃO"
              className={"ddIconItem"}
              src={infoIcon}
            />
            <p>SOBRE O REDAÇÃO</p>
          </div>
          <hr />
          <div className={"dropdownItem"} onClick={logout}>
            <img alt="SAIR" className={"ddIconItem"} src={logoutIcon} />
            <p>SAIR</p>
          </div>
        </div>
      )}
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
// import PropTypes from "prop-types";
// import logoImg from "../../assets/logo.png";
// import logoImgHomolog from "../../assets/logoHml.png";
// import dropdownIcon from "../../assets/dropdownIcon.png";
// import logoutIcon from "../../assets/logoutIcon.png";
// import diagnosticIcon from "../../assets/diagnosticIcon.png";
// import homeIcon from "../../assets/homeIcon.png";
// import infoIcon from "../../assets/infoIcon.png";
// import rulesIcon from "../../assets/rulesIcon.png";
// import tokenIcon from "../../assets/tokenIcon.png";
// import trainingIcon from "../../assets/trainingIcon.png";
// import proposeIcon from "../../assets/proposeIcon.png";
// import userAvatar from "../../assets/user.png";
// import InfoIcon from "@mui/icons-material/Info";
// import { Container, ToggleButton } from "./styles";
// import dotenv from "dotenv";

// interface Props {
//   profile: string;
//   menu: string;
// }
// export function Topbar(props: Props) {
//   let history = useHistory();
//   const [open, setOpen] = useState(false);
//   const [isStaging, setIsStaging] = useState(false);

//   useEffect(() => {
//     dotenv.config({ path: ".env.staging" });
//     setIsStaging(
//       process.env.REACT_APP_API_URL === "https://redacao-api-homolog.pr.gov.br/"
//     );
//   }, []);

//   function handleClick() {
//     setOpen(!open);
//   }

//   return (
//     <Container active={open} style={{ backgroundColor: isStaging ? '#12AA33' : 'var(--blue-light)' }}>
//       <ToggleButton onClick={handleClick}>
//         <span className={"bar"}></span>
//         <span className={"bar"}></span>
//         <span className={"bar"}></span>
//       </ToggleButton>
//       <img
//         id="logo"
//         src={isStaging ? logoImgHomolog :  logoImg}
//         onClick={() => history.push("/home")}
//         alt={"Redação"}
//         width={"200rem"}
//       />

//       {/* MOBILE */}
//       {!open && (
//         <div style={{ marginRight: "1%" }}>
//           {(() => {
//             switch (props.profile) {
//               case "8": // STUDENT
//                 return <>{GetGlobalButtons(open)}</>;
//               case "10": // TEACHER
//                 return <>{GetGlobalButtons(open)}</>;
//               case "11": // ADMINISTRATOR
//                 return <>{GetGlobalButtons(open)}</>;
//               default:
//                 break;
//             }
//           })()}
//         </div>
//       )}
//       {open && (
//         <div style={{ marginRight: "1%" }}>
//           {(() => {
//             console.log("click");
//             switch (props.profile) {
//               case "8": // STUDENT
//                 return (
//                   <>
//                     {GetGlobalButtons(open)}

//                     <button
//                       {...(props.menu === "home")}
//                       onClick={() => history.push("/student/home")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="VOLTAR AO INÍCIO"
//                         className={"ddIconItem"}
//                         src={homeIcon}
//                       />
//                       VOLTAR AO INÍCIO
//                     </button>

//                     <button
//                       {...(props.menu === "rules")}
//                       onClick={() => history.push("/student/CorrectionRules")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="INSTRUÇÕES"
//                         className={"ddIconItem"}
//                         src={rulesIcon}
//                       />
//                       INSTRUÇÕES
//                     </button>

//                     <button
//                       {...(props.menu === "train")}
//                       onClick={() => history.push("/student/essay/new")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="TREINAR REDAÇÃO"
//                         className={"ddIconItem"}
//                         src={trainingIcon}
//                       />
//                       TREINAR REDAÇÃO
//                     </button>
//                     <button
//                       {...(props.menu === "rules")}
//                       onClick={() => history.push("/student/SearchByToken")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="BUSCAR POR TOKEN"
//                         className={"ddIconItem"}
//                         src={tokenIcon}
//                       />
//                       BUSCAR POR TOKEN
//                     </button>
//                     <button
//                       {...(props.menu === "info")}
//                       onClick={() => history.push("/About/Info")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="INFORMAÇÕES"
//                         className={"ddIconItem"}
//                         src={infoIcon}
//                       />
//                       INFORMAÇÕES
//                     </button>
//                     <button
//                       onClick={() => history.push("/logout")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="SAIR"
//                         className={"ddIconItem"}
//                         src={logoutIcon}
//                       />
//                       SAIR
//                     </button>
//                   </>
//                 );
//               case "10": // TEACHER
//                 return (
//                   <>
//                     {GetGlobalButtons(open)}
//                     <button
//                       {...(props.menu === "home")}
//                       onClick={() => history.push("/teacher/home")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="VOLTAR AO INÍCIO"
//                         className={"ddIconItem"}
//                         src={homeIcon}
//                       />
//                       VOLTAR AO INÍCIO
//                     </button>
//                     {/* <button
//                       {...(props.menu === "home")}
//                       onClick={() => history.push("/teacher/diagnostic/Diagnostic")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="DIAGNÓSTICO"
//                         className={"ddIconItem"}
//                         src={diagnosticIcon}
//                       />
//                       DIAGNÓSTICO
//                     </button> */}
//                     <button
//                       {...(props.menu === "train")}
//                       onClick={() => history.push("/teacher/essay/new")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="PROPOR REDAÇÃO"
//                         className={"ddIconItem"}
//                         src={proposeIcon}
//                       />
//                       PROPOR REDAÇÃO
//                     </button>
//                     <button
//                       {...(props.menu === "train")}
//                       onClick={() => history.push("/teacher/PracticeEssay")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="TREINAR REDAÇÃO"
//                         className={"ddIconItem"}
//                         src={trainingIcon}
//                       />
//                       TREINAR REDAÇÃO
//                     </button>
//                     <button
//                       {...(props.menu === "info")}
//                       onClick={() => history.push("/About/Info")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="INFORMAÇÕES"
//                         className={"ddIconItem"}
//                         src={infoIcon}
//                       />
//                       INFORMAÇÕES
//                     </button>
//                     <button
//                       onClick={() => history.push("/logout")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="SAIR"
//                         className={"ddIconItem"}
//                         src={logoutIcon}
//                       />
//                       SAIR
//                     </button>
//                   </>
//                 );
//               case "11": // ADMINISTRATOR
//                 return (
//                   <>
//                     <button
//                       style={{ display: "none" }}
//                       {...(props.menu === "home")}
//                       onClick={() => history.push("/administrator/home")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="VOLTAR AO INÍCIO"
//                         className={"ddIconItem"}
//                         src={homeIcon}
//                       />
//                       VOLTAR AO INÍCIO
//                     </button>
//                     {/* <button
//                       {...(props.menu === "home")}
//                       onClick={() => history.push("/teacher/diagnostic/Diagnostic")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="DIAGNÓSTICO"
//                         className={"ddIconItem"}
//                         src={diagnosticIcon}
//                       />
//                       DIAGNÓSTICO
//                     </button> */}
//                     <button
//                       {...(props.menu === "info")}
//                       onClick={() => history.push("/About/Info")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="INFORMAÇÕES"
//                         className={"ddIconItem"}
//                         src={trainingIcon}
//                       />
//                       INFORMAÇÕES
//                     </button>
//                     <button
//                       onClick={() => history.push("/logout")}
//                       className={"Button"}
//                     >
//                       <img
//                         alt="SAIR"
//                         className={"ddIconItem"}
//                         src={logoutIcon}
//                       />
//                       SAIR
//                     </button>

//                     {GetGlobalButtons(open)}
//                   </>
//                 );
//               default:
//                 break;
//             }
//           })()}
//         </div>
//       )}
//       {/* {!isStaging && (
//           <h1 className="txtHml">HOMOLOGAÇÃO</h1>
//       )} */}
//     </Container>
//   );
// }

// Topbar.propTypes = {
//   container: PropTypes.instanceOf(
//     typeof Element === "undefined" ? Object : Element
//   ),
// };

// function GetGlobalButtons(props: boolean) {
//   let history = useHistory();
//   const [profileDescription, setProfileDescription] = React.useState("");
//   const [showDropdown, setShowDropdown] = useState(false);
//   const [isStudent, setIsStudent] = useState(false);
//   const [isProfessor, setIsProfessor] = useState(false);
//   const [pathInicio, setPathInicio] = useState();
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     switch (localStorage.getItem("ProfileId")) {
//       case "11":
//         setProfileDescription("Administrador");
//         setPathInicio("/administrator/home");
//         break;
//       case "10":
//         setProfileDescription("Docente");
//         setPathInicio("/teacher/home");
//         setIsProfessor(true);
//         break;
//       case "8":
//         setProfileDescription("Estudante");
//         setPathInicio("/student/home");
//         setIsStudent(true);
//         break;
//     }
//     if (window.innerWidth < 900) {
//       setIsMobile(true);
//       //console.log("wInnerWidth", window.innerWidth);
//     }
//     //console.log("Mobile?", isMobile);
//   }, []);

//   return (
//     <div
//       style={{ cursor: "pointer" }}
//       onMouseEnter={() => setShowDropdown(true)}
//       onMouseLeave={() => setShowDropdown(false)}
//     >
//       <img
//         alt=""
//         className={"avatarImg1"}
//         src={localStorage.getItem("Picture") ?? userAvatar}
//       />

//       <div
//         className={"ddName"}
//         style={
//           !props
//             ? { display: "flex", flexDirection: "row" }
//             : { display: "flex", flexDirection: "column" }
//         }
//       >
//         <img alt="" className={"ddIcon"} src={dropdownIcon} />
//         <span id="profile">
//           <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
//             {localStorage.getItem("Name")?.toUpperCase()}
//           </p>
//           <p>{profileDescription}</p>
//         </span>

//         <img
//           alt=""
//           className={"avatarImg"}
//           src={localStorage.getItem("Picture") ?? userAvatar}
//         />
//       </div>
//       {/* DESKTOP */}
//       {showDropdown && !isMobile && (
//         <div className="dropdownMenu">
//           <div
//             className={"dropdownItem"}
//             onClick={() => history.push(pathInicio)}
//           >
//             <img
//               alt="VOLTAR AO INÍCIO"
//               className={"ddIconItem"}
//               src={homeIcon}
//             />
//             <p>VOLTAR AO INÍCIO</p>
//           </div>
//           <hr />
//           {isProfessor && (
//             <>
//               {/* <div
//                 className={"dropdownItem"}
//                 onClick={() => history.push("/teacher/diagnostic/Diagnostic")}
//               >
//                 <img
//                   alt="DIAGNÓSTICO"
//                   className={"ddIconItem"}
//                   src={diagnosticIcon}
//                 />
//                 <p>DIAGNÓSTICO</p>
//               </div> */}
//               <div
//                 className={"dropdownItem"}
//                 onClick={() => history.push("/teacher/essay/new")}
//               >
//                 <img
//                   alt="PROPOR REDAÇÃO"
//                   className={"ddIconItem"}
//                   src={proposeIcon}
//                 />
//                 <p>PROPOR REDAÇÃO</p>
//               </div>
//               <hr />
//               <div
//                 className={"dropdownItem"}
//                 onClick={() => history.push("/teacher/PracticeEssay")}
//               >
//                 <img
//                   alt="TREINAR REDAÇÃO"
//                   className={"ddIconItem"}
//                   src={trainingIcon}
//                 />
//                 <p>TREINAR REDAÇÃO</p>
//               </div>
//               <hr />
//             </>
//           )}
//           {isStudent && (
//             <>
//               <div
//                 className={"dropdownItem"}
//                 onClick={() => history.push("/student/essay/new")}
//               >
//                 <img
//                   alt="TREINAR REDAÇÃO"
//                   className={"ddIconItem"}
//                   src={trainingIcon}
//                 />
//                 <p>TREINAR REDAÇÃO</p>
//               </div>
//               <hr />
//               <div
//                 className={"dropdownItem"}
//                 onClick={() => history.push("/student/CorrectionRules")}
//               >
//                 <img
//                   alt="INSTRUÇÕES"
//                   className={"ddIconItem"}
//                   src={rulesIcon}
//                 />
//                 <p>INSTRUÇÕES</p>
//               </div>
//               <hr />
//               <div
//                 className={"dropdownItem"}
//                 onClick={() => history.push("/student/SearchByToken")}
//               >
//                 <img
//                   alt="BUSCAR POR TOKEN"
//                   className={"ddIconItem"}
//                   src={tokenIcon}
//                 />
//                 <p>BUSCAR POR TOKEN</p>
//               </div>
//               <hr />
//             </>
//           )}
//           <div
//             className={"dropdownItem"}
//             onClick={() => history.push("/About/Info")}
//           >
//             <img
//               alt="SOBRE O REDAÇÃO"
//               className={"ddIconItem"}
//               src={infoIcon}
//             />
//             <p>SOBRE O REDAÇÃO</p>
//           </div>
//           <hr />
//           <div
//             className={"dropdownItem"}
//             onClick={() => history.push("/logout")}
//           >
//             <img alt="SAIR" className={"ddIconItem"} src={logoutIcon} />
//             <p>SAIR</p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }
