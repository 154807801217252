import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 1%;
  padding: 1rem;
  background: var(--white);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  overflow-x: hidden;

  h3 {
    display: block;
    width: 100%;
  }
  div {
    display: flex;
    flex-direction: row;
    margin: 15px;
    background: var(--white);
    border-width: 0.15rem;
    border-style: solid;
    border-color: var(--background);
    width: 100%;
    height: 3rem;
    justify-content: left;

    h3 {
      display: block;
      width: 30%;
    }

    .divHeader {
      display: flex;
      flex-direction: row;
      margin: 15px;
      background: #1978b0;
      width: 100%;
      height: 3rem;
      justify-content: left;
    }

    p {
      display: block;
      width: 30%;
    }
    button {
      background: var(--green);
      color: var(--white);
      height: 2rem;
      width: 10rem;
      border-radius: 0.5rem;
      margin: 0.2rem;
    }
  }

  table {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 8px;
    padding: 0 1% 0 1%;
  }

  #thead {
    font-size: 23px;
    background-color: var(--blue-light);
    color: var(--white);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    text-align: left;
  }

  #theadName {
    text-align: left;
    width: 40%;
    // width: 35%;
  }

  #theadStage {
    text-align: left;
    width: 30%;
    // width: 20%;
  }

  #theadC1 {
    text-align: center;
    width: 5%;
  }

  #theadC2 {
    text-align: center;
    width: 5%;
  }

  #theadC3 {
    text-align: center;
    width: 5%;
  }

  #theadC4 {
    text-align: center;
    width: 5%;
  }

  #theadC5 {
    text-align: center;
    width: 5%;
  }

  #theadGrade {
    text-align: center;
    width: 22.5%;
    // width: 12%;
  }

  #theadAction {
    text-align: center;
    width: 7.5%;
    // width: 8%;
  }

  #tbody {
    background-color: var(--white);
    color: var(--blue-light);
  }

  #tbodyName {
    text-align: left;
    width: 40%;
    // width: 35%;
  }

  #tbodyStage {
    text-align: left;
    fontweight: 400;
    width: 30%;
    // width: 20%;
  }
  
  #tbodyC1 {
    text-align: center;
    width: 5%;
  }

  #tbodyC2 {
    text-align: center;
    width: 5%;
  }

  #tbodyC3 {
    text-align: center;
    width: 5%;
  }

  #tbodyC4 {
    text-align: center;
    width: 5%;
  }

  #tbodyC5 {
    text-align: center;
    width: 5%;
  }

  #tbodyGrade {
    text-align: center;
    width: 22.5%;
    // width: 12%;
  }

  #tbodyAction {
    text-align: right;
    width: 7.5%;
    // width: 8%;
  }

  @media (max-width: 600px) {
    #tbodyStage {
      display: none;
    }
    #theadGrade {
      display: none;
    }
    #theadC1 {
      display: none;
    }
    #theadC2 {
      display: none;
    }
    #theadC3 {
      display: none;
    }
    #theadC4 {
      display: none;
    }
    #theadC5 {
      display: none;
    }
    #tbodyC1 {
      display: none;
    }
    #tbodyC2 {
      display: none;
    }
    #tbodyC3 {
      display: none;
    }
    #tbodyC4 {
      display: none;
    }
    #tbodyC5 {
      display: none;
    }
    #tbodyGrade {
      display: none;
    }
    #theadStage {
      display: none;
    }
    #theadName {
      text-align: center;
    }
    #theadAction {
      text-align: center;
    }
  }
`;
