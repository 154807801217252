import React, { useState, useEffect } from "react";
import { Topbar } from "../../../component/Topbar";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Paper,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core/";
import { apiSecure } from "domain/api";
import moment from "moment";
import { GetMotivatingText } from "domain/theme/service";
import TodayIcon from "@material-ui/icons/CalendarToday";
import Quill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function UpdateTheme(props) {
  const classes = useStyles();
  const [type, setType] = useState();
  const [theme, setTheme] = useState({});
  const [school, setSchool] = useState([]);
  const [minWords, setMinWords] = useState(150);
  const [maxWords, setMaxWords] = useState(150);
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [isDeleted, setIsDeleted] = useState();
  const [send, setSend] = useState(false);
  const [motivatingTexts, setMotivatingTexts] = useState({});
  const [essayDetails, setEssayDetails] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogInativar, setOpenDialogInativar] = useState(false);
  const [openDialogAtivar, setOpenDialogAtivar] = useState(false);
  const [openDialogAtualizar, setOpenDialogAtualizar] = useState(false);
  const [essayLoadDetails, setEssayLoadDetails] = useState();
  const [essayData, setEssayData] = useState({});
  const [themeData, setThemeData] = useState({});
  const [genre, setGenre] = useState([]);
  const [classSchool, setClassSchool] = useState();
  const [deleteEssay, setDeleteEssay] = useState(false);
  const [revertDeleteEssay, setRevertDeletedEssay] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [readOnly, setReadOnly] = useState(localStorage.getItem("IsReadOnly"));

  var firstLoad = false;

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list:"ordered" },
        { list:"bullet" },
        { indent:"-1" },
        { indent:"+1" },
      ],
    ],
    };

  useEffect(() => {
    if (firstLoad === false) {
      apiSecure
        .get("/Essay/" + props.history.location.state.id)
        .then(function (response) {
          minWordsSet(response.data.minAmountWords);
          maxWordsSet(response.data.maxAmountWords);
          setEssayDetails(response.data.details);
          setTheme(response.data.themeId);

          setEssayData(response.data);
          setEssayLoadDetails(response.data.details);
          setEndDate(moment(response.data.endDate).format("YYYY-MM-DD"));
          getThemeDetails(response.data.themeId);

          if (response.data.isDeleted === true) {
            console.log("if in");
            document.getElementById("btnAtivarRedacao").style.display = "block";
            document.getElementById("btnInativarRedacao").style.display =
              "none";
          } else {
            document.getElementById("btnAtivarRedacao").style.display = "none";
            document.getElementById("btnInativarRedacao").style.display =
              "block";
          }
          getClasses();
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function getThemeDetails(props) {
      apiSecure
        .get("/theme/" + props)
        .then(function (response) {
          setThemeData(response.data);
          getGenres(response.data.themeGenreId);
          getTypes(response.data.themeTypeId);
          console.info(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function getClasses() {
      apiSecure
        .get("/Essay/teacher/l/classrooms/" + props.history.location.state.id)
        .then(function (response) {
          console.info(response.data);
          setClassSchool(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function getTypes(props) {
      apiSecure
        .get("/Theme/l/type")
        .then(function (response) {
          const result = response.data.find((item) => item.id === props);
          setType(result.description);
        })
        .catch(function (error) {});
    }

    function getGenres(props) {
      apiSecure
        .get("/Theme/l/genre")
        .then(function (response) {
          const result = response.data.find((item) => item.id === props);
          setGenre(result.description);
        })
        .catch(function (error) {});
    }
  }, [school]);

  useEffect(() => {
    if (deleteEssay === true) {
      DeleteEssay();
    }

    function DeleteEssay() {
      apiSecure
        .delete("/Essay/" + props.match.params.token)
        .then(function (response) {
          return (window.location = "/home");
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  }, [deleteEssay]);

  useEffect(() => {
    if (revertDeleteEssay === true) {
      RevertDeleteEssay();
    }

    function RevertDeleteEssay() {
      apiSecure
        .get("/Essay/revert/" + props.match.params.token)
        .then(function (response) {
          return (window.location = "/home");
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  }, [revertDeleteEssay]);

  useEffect(() => {
    if (send === true) {
      setOpenDialogAtualizar(true);
      apiSecure
        .put("/Essay/Teacher", {
          themeId: theme,
          startDate: moment(startDate).format("YYYY-MM-DDTHH:MM:ss.SSSZ"),
          endDate: moment(endDate).format("YYYY-MM-DDTHH:MM:ss.SSSZ"),
          details: essayDetails,
          minAmountWords: Number(minWords),
          maxAmountWords: Number(maxWords),
          token: props.match.params.token,
        })
        .then(function (response) {
          setSend(false);
          setUpdateState(true);
          setTimeout(() => {
            setUpdateState(false);
          }, 5000);
        })
        .catch(function (error) {
          console.error(error);
          setSend(false);
        });
    }
  }, [send]);

  function maxWordsSet(value) {
    if (value <= 150) {
      setMaxWords(150);
    } else {
      setMaxWords(value);
    }
  }

  function minWordsSet(value) {
    // if (value <= 150) {
    // 	setMinWords(value);
    // } else {
    // 	setMinWords(value);
    // }
    setMinWords(value);
  }

  return (
    <>
      {theme && theme > 0 && (
        <GetMotivatingText id={theme} callback={(e) => setMotivatingTexts(e)} />
      )}
      <Topbar profile="10" menu="home" />
      <div
        class={classes.container}
        style={{
          background: "var(--blue-light)",
          borderRadius: "0.25rem",
          padding: "1%",
          color: "var(--white)",
          textTransform: "uppercase",
        }}
      >
        <center>
          <h2>Atualizar proposta</h2>
        </center>
      </div>
      <div class={classes.container}>
        <Paper elevation={1} className={classes.paper}>
          <div class={classes.right}>
            <center>
              <p
                style={{
                  color: "var(--blue-light)",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginTop: "5px",
                }}
              >
                Tipo
              </p>
              <p> {type} </p>
              <p
                style={{
                  color: "var(--blue-light)",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginTop: "5px",
                }}
              >
                Gênero{" "}
              </p>
              <p>{genre}</p>
              <p
                style={{
                  color: "var(--blue-light)",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginTop: "5px",
                }}
              >
                Tema{" "}
              </p>
              <p>{themeData.title}</p>
            </center>
            <div class={classes.content} style={{ marginTop: "5%" }}></div>
            <TextField
              type={"number"}
              className={classes.datePickers}
              label="Mínimo de palavras"
              value={minWords}
              InputProps={{
                inputProps: {
                  min: 150,
                  max: 400000,
                  "aria-label": "weight",
                },
              }}
              onChange={(e) => minWordsSet(e.target.value)}
            />
            <TextField
              type={"number"}
              className={classes.datePickers}
              label="Máximo de palavras"
              value={maxWords}
              InputProps={{
                inputProps: {
                  min: 150,
                  max: 400000,
                  "aria-label": "weight",
                },
              }}
              onChange={(e) => maxWordsSet(e.target.value)}
            />
            <TextField
              id="date"
              label="Data da proposta"
              type="date"
              disabled
              value={moment(essayData.startDate).format("YYYY-MM-DD")}
              defaultValue={moment().format("YYYY-MM-DD")}
              className={classes.datePickers}
              onChange={(e) =>
                setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
              }
              InputLabelProps={{
                shrink: true,
              }}
              customInput={<TodayIcon style={{ color: "red" }} />}
            />
            <TextField
              id="date"
              label="Data de finalização"
              type="date"
              value={moment(endDate).format("YYYY-MM-DD")}
              //defaultValue={moment(endDate).format("YYYY-MM-DD")}
              className={classes.datePickers}
              onChange={(e) => setEndDate(moment(e.target.value).format(""))}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </Paper>

        <Paper elevation={1} className={classes.paper}>
          <div class={classes.left}>
            <p
              style={{
                color: "var(--blue-light)",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              TURMAS ATRIBUIDAS
            </p>
            {classSchool &&
              classSchool.map((e) => (
                <p>
                  {e.classroomDescription} - {e.schoolDescription}
                </p>
              ))}
            <Quill
              value={essayDetails}
              placeholder="Proposta"
              onChange={(content, delta, source, editor) => {
                const newValue = editor.getHTML();
                setEssayDetails(newValue);
              }}
              modules={modules}
              className="ql-editor"
              style={{
                width: "98%",
                margin: "2% 0 5% 0",
                padding: "5px",
                textAlign: "justify",
                height: "50vh",
              }}
            ></Quill>
            {/* <TextField
              id="outlined-multiline-static"
              label="Proposta"
              value={essayDetails}
              defaultValue={essayLoadDetails}
              multiline
              onChange={(e) => setEssayDetails(e.target.value)}
              rows={15}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              style={{
                width: "98%",
                margin: "2%",
                padding: "5px",
                textAlign: "justify",
              }}
            /> */}
            <div class={classes.container}>
              <Button
                id="btnInativarRedacao"
                variant="contained"
                color="secondary"
                className={classes.buttons}
                onClick={() => setOpenDialogInativar(true)}
                style={{
                  background: "var(--red)",
                  color: "var(--white)",
                }}
              >
                Inativar proposta
              </Button>

              <Button
                id="btnAtivarRedacao"
                variant="contained"
                className={classes.buttons}
                onClick={() => setOpenDialogAtivar(true)}
                style={{
                  background: "var(--green)",
                  color: "var(--white)",
                }}
              >
                Ativar proposta
              </Button>

              <Button
                variant="contained"
                className={classes.buttons}
                style={{
                  backgroundColor: "var(--blue-light)",
                  color: "var(--white)",
                }}
                onClick={() => setSend(true)}
              >
                Atualizar proposta
              </Button>
            </div>
          </div>
          {/* {updateState && (
            <>
              <center>
                <div
                  style={{
                    backgroundColor: "var(--blue-light)",
                    width: "94vh",
                    margin: "2%",
                    borderRadius: "0.25rem",
                  }}
                >
                  <h1 style={{ color: "var(--white)" }}>PROPOSTA ATUALIZADA</h1>
                </div>
              </center>
            </>
          )} */}
        </Paper>
      </div>
      <div>
        {motivatingTexts.length > 0 && (
          <>
            <div style={{ width: "98%", margin: "1%" }}>
              <div
                style={{
                  background: "var(--blue-light)",
                  padding: "1%",
                  textTransform: "uppercase",
                  borderTopRightRadius: "0.25rem",
                  borderTopLeftRadius: "0.25rem",
                }}
              >
                <center>
                  <Typography variant={"h6"} style={{ color: "var(--white)" }}>
                    {" "}
                    Textos de Apoio
                  </Typography>
                </center>
              </div>
              <div
                style={{
                  background: "var(--white)",
                  padding: "1% 5%",
                  borderBottomRightRadius: "0.25rem",
                  borderBottomLeftRadius: "0.25rem",
                  lineHeight: "1.5",
                }}
              >
                {motivatingTexts.map((e) => (
                  <>
                    {e.isFile && (
                      <img
                        alt=""
                        style={{
                          width: "50%",
                        }}
                        src={e.text}
                      />
                    )}
                    {!e.isFile && (
                      <div
                        style={{ textAlign: "justify", textIndent: 50 }}
                        dangerouslySetInnerHTML={{
                          __html: e.text,
                        }}
                      ></div>
                    )}
                    <Divider style={{ margin: 30 }} />
                  </>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <Dialog
        open={openDialogInativar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => setOpenDialogInativar(false)}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: "var(--blue-light)", textTransform: "uppercase" }}
        >
          Você gostaria de inativar a proposta de redação?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              Caso seus alunos já tenham iniciado a redação, o progresso será
              perdido.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "var(--blue-light)" }}
            onClick={() => setOpenDialogInativar(false)}
          >
            {" "}
            Cancelar
          </Button>
          <Button
            style={{ color: "var(--red)" }}
            onClick={() => setDeleteEssay(true)}
          >
            Quero inativar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogAtivar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => setOpenDialogAtivar(false)}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: "var(--blue-light)", textTransform: "uppercase" }}
        >
          Você gostaria de ativar a proposta de redação?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              Ao ativar a proposta, seus alunos poderão escrever sobre o tema.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "var(--blue-light)" }}
            onClick={() => setOpenDialogAtivar(false)}
          >
            {" "}
            Cancelar
          </Button>
          <Button
            style={{ color: "var(--green)" }}
            onClick={() => setRevertDeletedEssay(true)}
          >
            Quero ativar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogAtualizar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => setOpenDialogAtualizar(false)}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: "var(--blue-light)", textTransform: "uppercase" }}
        >
          Atenção
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>Sua proposta foi atualizada.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenDialogAtualizar(false)}>
            {" "}
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "98%",
      margin: "1%",
      overflowX: "hidden",
    },
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "column",
      width: "98%",
      margin: "1%",
      overflowX: "hidden",
    },
    "@media (min-width: 1280px)": {
      flexDirection: "row",
      width: "98%",
      margin: "1%",
      justifyContent: "center",
      overflowX: "hidden",
    },
    marginTop: "1%",
  },

  content: {
    display: "flex",
    flexDirection: "row",
  },

  paper: {
    magin: "0.1%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100%",
      padding: "5%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "column",
      width: "100%",
      padding: "5%",
    },
    "@media (min-width: 1280px)": {
      flexDirection: "column",
      width: "50%",
      margin: "0.1%",
    },
  },

  centered: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    marginLeft: "5%",
    marginTop: "1%",
    justifyContent: "center",
    alignItems: "center",
  },

  right: {
    display: "flex",
    flexDirection: "column",
    margin: "1%",
    width: "98%",
  },

  left: {
    display: "flex",
    margin: "1%",
    flexDirection: "column",
    width: "98%",
    float: "center",
    justifyContent: "center",
    alignItems: "center",
  },

  buttons: {
    margin: "0.5%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      width: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "row",
      width: "50%",
    },
    "@media (min-width: 1280px)": {
      flexDirection: "row",
      width: "50%",
    },
  },

  datePickers: {
    minWidth: "45%",
    marginRight: "1%",
    marginLeft: "1%",
    marginTop: "1%",
    justifyContent: "left",
    alignItems: "left",
  },
}));
