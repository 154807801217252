import React, { useState } from "react";
import LineCard from "component/LineCard";
import { GetCorrectionsHistoryStudent } from "domain/correction/service";
import moment from "moment";
import "moment/min/locales";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

export default function CorrectionHistory(props) {
  const [correctionHistory, setCorrectionHistory] = useState([]);

  function GetScore(e) {
    //console.log(e);
    let score = e.score > 40 ? e.score * 0.4 : e.score;
    let maxScore = e.status == 3 ? "40" : "100";
    let reviewScore = e.reviewScore ? e.reviewScore : 0;
    return `${Number(score + reviewScore).toFixed(0)}/${maxScore}`;
  }

  return (
    <>
      <GetCorrectionsHistoryStudent
        id={props.essayId}
        token={props.token}
        callback={(e) => setCorrectionHistory(e)}
      />
      {correctionHistory.length > 0 && (
        <>
          <div
            style={{
              margin: "1% 1% 0% 1%",
              backgroundColor: "var(--blue-light)",
              padding: "1%",
              borderTopLeftRadius: "0.25rem",
              borderTopRightRadius: "0.25rem",
            }}
          >
            <center>
              <Typography variant={"h6"} style={{ color: "var(--white)" }}>
                HISTÓRICO DE CORREÇÕES
              </Typography>
            </center>
          </div>
          <div
            style={{
              margin: "0% 1% 1% 1%",
              backgroundColor: "var(--white)",
              padding: "1%",
              borderBottomLeftRadius: "0.25rem",
              borderBottomRightRadius: "0.25rem",
              color: "var(--blue-dark)",
            }}
          >
            {correctionHistory.map((e) => (
              <>
                <LineCard
                  key={e}
                  topLeft={
                    e.statusDescription +
                    `: Realizada ${moment(e.reviewedAt ?? e.deliveredAt)
                      .utc(true)
                      .utcOffset("-03:00")
                      .locale("pt-br")
                      .startOf("second")
                      .fromNow()}`
                  }
                  // status={e.status}
                  // statusDescription={e.statusDescription}
                  essayId={e.essayId}
                  teacherName={e.reviewerName}
                  description={e.description}
                  title={``}
                  accordionText={
                    e.reviewText !== undefined &&
                    `PROFESSOR: ${e.reviewerName}\n` +
                      `DEVOLUTIVA DO PROFESSOR: ${e.reviewText}`
                  }
                  topRightText={GetScore(e)}
                  data={moment(e.deliveredAt)
                    .utc(true)
                    .utcOffset("-03:00")
                    .format("DD/MM/YYYY HH:mm")}
                />
              </>
            ))}
          </div>
        </>
      )}
    </>
  );
}
