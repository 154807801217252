import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "fontsource-roboto";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "flex",
		width: "100%",
		cursor: "pointer",
		margin: "10",
	},
	container: {
		width: "100%",
	},
	accordion: {
		"&:hover": {
			backgroundColor: "#f2f1f0",
		},
	},
	topLine: {
		display: "flex",
		flexDirection: "row",
	},
	middleLine: {
		order: 2,
		fontFamily: "Roboto",
		fontStyle: "normal",
		fontWeight: "normal",
		fontSize: "11px",
		lineHeight: "20px",
	},
	topRight: {
		marginLeft: "auto",
		[theme.breakpoints.down("sm")]: {
			fontSize: 15,
		},
	},
	gray: {
		color: "gray",
	},
	description: {},
}));

function onClick() {}
export default function LineCard(props) {
	const classes = useStyles();

	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Accordion
			onClick={() => onClick()}
			expanded={expanded === "panel1"}
			onChange={handleChange("panel1")}
			className={classes.root}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1bh-content"
				id="panel1bh-header"
				className={classes.accordion}
			>
				<div className={classes.container}>
					<div className={classes.topLine}>
						<Tooltip title={props.data} aria-label="add">
							<p style={{ marginTop: "auto", marginBottom: "auto" }}>
								{props.topLeft}
							</p>
						</Tooltip>
						<h4 className={classes.topRight}>{props.topRightText}</h4>
					</div>
					<div className={classes.middleLine}>
						<Typography variant={"body2"}>{props.title}</Typography>
					</div>

					<div>
						<p>{props.description}</p>
						<p className={classes.gray}>{props.footer}</p>
					</div>
				</div>
			</AccordionSummary>
			{props.accordionText !== "undefined" && props.accordionText.length > 0 && (
				<AccordionDetails style={{ borderTop: "1px solid #C5C5C5" }}>
					<p style={{ whiteSpace: "break-spaces", fontSize: 14 }}>
						{props.accordionText}
					</p>
				</AccordionDetails>
			)}
		</Accordion>
	);
}
