import React, { useState, useRef } from 'react';
import './stylesInfoNoteNoURL.css';

interface InfoNoteNoURLProps {
  tooltipText: string;
}

const InfoNoteNoURL: React.FC<InfoNoteNoURLProps> = ({ tooltipText }) => {
  const [isTooltipVisible, setTooltipVisibility] = useState<boolean>(false);
  const hideTooltipTimer = useRef<NodeJS.Timeout | null>(null);

  const showTooltip = () => {
    if (hideTooltipTimer.current) {
      clearTimeout(hideTooltipTimer.current);
      hideTooltipTimer.current = null;
    }
    setTooltipVisibility(true);
  };

  const hideTooltip = () => {
    if (hideTooltipTimer.current) {
      clearTimeout(hideTooltipTimer.current);
    }
    hideTooltipTimer.current = setTimeout(() => {
      setTooltipVisibility(false);
      hideTooltipTimer.current = null;
    }, 2000);
  };

  React.useEffect(() => {
    return () => {
      if (hideTooltipTimer.current) {
        clearTimeout(hideTooltipTimer.current);
      }
    };
  }, []);

  return (
    <div className="info-note-noUrl">
      <div
        className="info-icon-noUrl"
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        i
      </div>
      {isTooltipVisible && (
        <div
          className="tooltip-noUrl"
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
        >
          <div>{tooltipText}</div>
          <br />
        </div>
      )}
    </div>
  );
};

export default InfoNoteNoURL;
