import React, { useState } from "react";
import { Topbar } from "../../../component/Topbar";
import { useHistory } from "react-router-dom";
import {
  Header,
  Subheader,
  Line,
  Input,
  InputField,
  SubmitButton,
  ErrorMessage,
} from "./styles";
import api from "domain/api";

interface GetTokenResponse {
  response: boolean;
}

export default function SearchByToken() {
  const [tokenInput, setTokenInput] = useState("");
  const [isValid, setIsValid] = useState<GetTokenResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const baseURL = process.env.REACT_APP_API_URL || "http://localhost:5000/";
    const authToken = localStorage.getItem("Token");
    //console.log(authToken);

    try {
      //console.log(baseURL + "Essay/student/valid-token/" + tokenInput);
      const response = await api.get<GetTokenResponse>(
        baseURL + "Essay/student/valid-token/" + tokenInput,
        {
          headers: {
            // Adicione os headers necessários aqui
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setIsValid(response.data);
      if (response.data) {
        history.push(`essay/${tokenInput}`);
      } else {
        setErrorMessage("Token inválido");
      }
    } catch (error) {
      setErrorMessage("Erro ao validar o token");
    }
  };

  return (
    <>
      <Topbar profile="8" menu="home" />
      <Header>
        <h1>BUSCAR REDAÇÃO POR TOKEN</h1>
      </Header>
      <Subheader>
        <div style={{ textAlign: "center" }}>
          <p>Digite o seu token na caixa de pesquisa abaixo.</p>
        </div>
        <Line />
        <form onSubmit={handleSubmit}>
          <Input>
            <InputField
              type="text"
              placeholder="Digite seu token"
              value={tokenInput}
              onChange={(e) => setTokenInput(e.target.value)}
            />
            <SubmitButton type="submit">BUSCAR</SubmitButton>
          </Input>
        </form>
        {errorMessage && !isValid?.response && (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        )}
      </Subheader>
    </>
  );
}
