import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import googleLogo from "component/images/google_btn2.png";

export default function GoogleBtn(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  function oauthSignIn() {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
    }

    var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

    var form = document.createElement("form");
    form.setAttribute("method", "GET"); // Send as a GET request.
    form.setAttribute("action", oauth2Endpoint);

    var params = {
      client_id:
        "686929670145-2lqsdsibu1efbmrs279ucrvq07gml979.apps.googleusercontent.com",
      redirect_uri: process.env.REACT_APP_GREDIRECT,
      response_type: "token",
      scope:
        "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
      include_granted_scopes: "true",
      state: "pass-through value",
      prompt: "select_account",
      hd: "escola.pr.gov.br",
      promt: "select_account",
    };

    for (var p in params) {
      var input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();

    setSuccess(true);
    setLoading(false);
  }

  return (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Roboto"
        rel="stylesheet"
        type="text/css"
      />
      <script
        src="https://apis.google.com/js/platform.js?onload=renderButton"
        async
        defer
      ></script>

      <div className={classes.wrapper}>
        <Button
          className={classes.signin}
          variant="contained"
          onClick={() => oauthSignIn()}
          disabled={loading}
        >
          <img
            className={classes.imgGoogleButton}
			style={{marginLeft:"-20px"}}
            src={googleLogo}
            alt={"Entrar com o Google"}
          />
          <span className={classes.imgGoogleText} style={{marginRight:"40px", marginTop:"2.5px"}}>ENTRAR COM O GOOGLE</span>
          <span className={classes.imgGoogleTextMobile}>ENTRAR</span>
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "auto",
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  signin: {
    cursor: "pointer",
    width: "100%",
    height: 50,
    "&:hover": { backgroundColor: "#f0f0f0" },
    backgroundColor: "#fff",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  imgGoogleButton: {
    width: "30%",
    padding: 20,
    marginLeft: -20,
    "@media (max-width: 300px)": {
      width: "45%",
    },
  },
  "@media (max-width: 300px)": {
    imgGoogleText: {
      display: "none",
    },
	imgGoogleTextMobile:{
		marginRight: "25%",
	},
  },
  "@media (min-width: 301px)": {
	imgGoogleTextMobile:{
		display: "none",
	},
  },
}));
