import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 0.25rem;
  justify-content: center;
  padding: 1rem;
  margin: 0 auto;

  .items {
    width: 70%;
    border: 5;
    flex-direction: row;
  }

  .tabs {
    padding-right: 50px;
  }

  .inputs {
    margin-top: 3%;
    margin-bottom: 3%;
    width: 100%;
    @media (max-width: 1280px) {
      margin-top: 0;
      margin-bottom: 2%;
    }
  }

  .inputsClasses {
    margin-top: 3%;
    width: 100%;
  }

  .inputLast {
    margin-bottom: 3%;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 1280px) {
    flex-direction: column;
  }

  .card {
    @media (max-width: 1280px) {
      margin-bottom: 2%;
      width: 96.5vw;
      padding: 2vw;
    }
  }
`;

export const Content = styled.div`
  background: var(--white);
  color: var(--black);
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0.15rem;
  padding: 3rem;
  background: var(--white);
  border-radius: 0.25rem;

  .typography {
    margin-left: 0.2%;
  }

  .button {
    width: 100%;
    margin-top: 2rem;
    height: 4.5rem;
    margin: 1 auto;
    background: var(--green);
    color: var(--white);
    border: 0;
    border-radius: 0.25rem;
    font-weight: bold;
    font-size: 12pt;
  }

  .text {
    padding: 0.55rem;
    margin: 1 auto;
    margin-top: 2rem;
    height: 400px;
  }

  @media (max-width: 958px) {
    width: 98%;
    margin: 2% 1%;
    padding: 3%;
  }

  .ql-editor {
    margin: 0% 0px;
    padding: 0px;
    border-radius: "0.25rem";
    @media (max-width: 1280px) {
      min-height: 35vh;
    }
  }
  .ql-editor p {
    padding: 15px;
  }

  .ql-editor.ql-blank::before {
    padding-top: 15px;
  }
`;

export const Texts = styled.div`
  background: var(--white);
  color: var(--black);
  display: flex;
  flex-direction: column;
  width: 98%;
  margin: 0 auto;
  padding: 2rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
`;
