import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { apiSecure } from '../../domain/api'
import { Alert, Stack } from '@mui/material'

export var theme

export default function ThemesAutocomplete(props) {
  const [themes, setThemes] = useState([])
  let label = 'Temas'
  useEffect(() => {
    if (props.type > 0 && props.gender > 0) {
      getReasons()
    }

    console.log(props.owner)

    function getReasons() {
      apiSecure
        .get(
          '/Theme/type/' +
            props.type +
            '/genre/' +
            props.gender +
            '/owner/' +
            props.owner
        )
        .then(function (response) {
          setThemes(response.data)
          if (response.status === 204) {
            label = 'Não há temas disponíveis'
          }
          // console.log('RESPONSE TEMAS', response.data)
        })
        .catch(function (error) {})
    }
  }, [props.type, props.gender, props.requestList])

  const classes = useStyles()

  if (themes.length === 0 || undefined) {
    return (
      <Stack sx={{ width: '100%', marginBottom: 4 }} spacing={2}>
        <Alert severity="info">
          Sem temas disponíveis para esse gênero! Cadastre em "Criar Meus Temas"
          📒!!
        </Alert>
      </Stack>
    )
  } else
    return (
      <>
        <Autocomplete
          id="free-solo-dialog-demo"
          options={themes}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              {...params}
              themes={themes}
              className={classes.buttons}
              label={label}
              variant="outlined"
            />
          )}
          onChange={(event, newValue) => {
            if (newValue != null) {
              console.log(newValue.id)
              props.onSubmit(newValue.id)
            }
          }}
        />
      </>
    )
}

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginBottom: '4%',
    '@media (max-width: 958px)': {
      flexDirection: 'column',
    },
  },
}))
