import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Popper, MenuItem } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import reactStringReplace from "react-string-replace";

const Corrector = (props) => {
	const [load, setLoad] = useState();
	const [text, setText] = useState(props.text);
	const [result, setResult] = useState([]);
	const [resultado, setResultado] = useState([]);

	useEffect(() => {
		HandleJson(props.json);
		function HandleJson(json) {
			var object = JSON.parse(JSON.stringify(json.matches));
			object.map((item) => {
				var word = props.text.slice(item.offset, item.offset + item.length);
				var newItem = {
					word: word,
					replacements: item.replacements,
					message: item.message,
					length: item.length,
					offset: item.offset,
					category: item.rule.category.name,
					url: item.rule.urls,
				};
				setResult(result.push(newItem));
			});

			setResultado(result);

			setLoad(true);
		}
	}, []);

	var replaced = text;

	var offsetBase = 0;
	var counter = 0;
	function replaceText(item) {
		var text1 = String(replaced).substr(0, item.offset + offsetBase);
		var wrong = String(replaced).substr(item.offset + offsetBase, item.length);
		var text2 = String(replaced).substr(item.offset + offsetBase + item.length);

		var tag = "#TAG" + counter + "#";

		var diff = 0;

		if (wrong.length > tag.length) {
			diff = tag.length - wrong.length;
		}
		if (wrong.length < tag.length) {
			diff = tag.length - wrong.length;
		}

		offsetBase = offsetBase + diff;

		replaced = text1 + tag + text2;
		counter = counter + 1;
	}
	resultado.forEach(replaceText);

	var anotherCounter = 0;

	resultado.map((c) => (
		<div key={c.offset}>
			{
				(replaced = reactStringReplace(
					replaced,
					"#TAG" + anotherCounter + "#",
					(match, i) => (
						<OpenMenu
							key={c.offset}
							word={c.word}
							replacements={c.replacements}
							type={c.message}
							category={c.category}
							url={c.url}
						/>
					)
				))
			}

			{(anotherCounter = anotherCounter + 1)}
		</div>
	));

	if (load) {
		return <div style={{ whiteSpace: "break-spaces", textAlign: "justify", margin: "1%", lineHeight: "2" }}> {replaced} </div>;
	} else {
		return <div>Carregando...</div>;
	}
};
export default Corrector;

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			width: "90%",
			marginLeft: "5%",
		},
		[theme.breakpoints.between("sm", "md")]: {
			flexDirection: "column",
			width: "90%",
			marginLeft: "5%",
		},
		"@media (min-width: 1280px)": {
			flexDirection: "row",
			width: "90%",
			marginLeft: "5%",
			justifyContent: "center",
		},
		marginTop: "1%",
	},
	centered: {
		display: "flex",
		margin: "1%",
		width: "100%",
	},
	buttons: {
		display: "flex",
		margin: "1%",
		justifyContent: "center",
		alignItems: "center",
	},
	table: {
		marginRight: "10%",
		marginLeft: "10%",
		marginTop: "1%",
		justifyContent: "center",
		alignItems: "center",
	},
	textarea: {
		margin: "1%",
		minWidth: "100%",
	},
	typography: {
		margin: "1%",
		maxWidth: "100px",
		minHeight: "250px",
		justifyContent: "right",
		alignItems: "center",
	},
}));

function OpenMenu(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const [replacements, setReplacements] = useState([]);
	const [urls, setUrls] = useState([]);
	const [open, setOpen] = React.useState(false);
	const [loaded, setLoaded] = React.useState(false);
	const anchorRef = React.useRef(null);
	var color = "primary";
	const [cor, setCor] = useState();
	useEffect(() => {
		setReplacements(props.replacements);
		setUrls(props.url);

		var categoria = props.category;

		switch (categoria) {
			case "Acentuação":
				color = "#ffe0ad";
			case "Palavras Normalmente Confusas":
				color = "#ffe0ad";
				break;
			case "Calinadas":
				color = "#cef2d5";
				break;
			case "Capitalização":
				color = "#cef2d5";
				break;
			case "Composição":
				color = "#cef2d5";
				break;
			case "Erro de Escrita":
				color = "#cef2d5";
				break;
			case "Erros Ortográficos":
				color = "#cef2d5";
				break;
			case "Maiúsculo / Minúsculo":
				color = "#cef2d5";
				break;
			case "Palavras Compostas":
				color = "#cef2d5";
				break;
			case "Regras Específicas da Wikipédia":
				color = "#cef2d5";
				break;
			case "Sem Categoria Definida":
				color = "#cef2d5";
				break;
			case "Style":
				color = "#cef2d5";
				break;
			case "Confusão de Palavras":
				color = "#e1b6fc";
				break;
			case "Dicas de estilo para uma escrita criativa":
				color = "#e1b6fc";
				break;
			case "Erros de Tradução":
				color = "#e1b6fc";
				break;
			case "Escrita Criativa":
				color = "#e1b6fc";
				break;
			case "Frases-Feitas e Expressões Idiomáticas":
				color = "#e1b6fc";
				break;
			case "Regras de Marcas e Termos Registrados":
				color = "#e1b6fc";
				break;
			case "Semântica":
				color = "#e1b6fc";
				break;
			case "Análise de Texto":
				color = "#ed9332";
				break;
			case "Dicas de Redação":
				color = "#ed9332";
				break;
			case "Estilo":
				color = "#ed9332";
				break;
			case "Gramática Geral":
				color = "#ed9332";
				break;
			case "Pontuação":
				color = "#ed9332";
				break;
			case "Repetições":
				color = "#ed9332";
				break;
			case "Repetições (estilo)":
				color = "#ed9332";
				break;
			case "Sintaxe":
				color = "#ed9332";
				break;
			case "Contrações":
				color = "#b4bbfa";
				break;
			case "Desenvolvimento":
				color = "#b4bbfa";
				break;
			case "Linguagem Formal":
				color = "#b4bbfa";
				break;
			case "Redundância":
				color = "#b4bbfa";
				break;
			case "Regionalismos":
				color = "#b4bbfa";
				break;
			case "Tipografia":
				color = "#b4bbfa";
				break;
			case "Regras específicas da Wikipédia":
				color = "#ed9332";
				break;
			default:
				color = "#E5E4E2";
		}

		if (cor != color) {
			setCor(color);
		}

		setLoaded(true);
	}, []);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	}

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	if (loaded)
		return (
			<>
				<a
					ref={anchorRef}
					aria-controls={open ? "menu-list-grow" : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
					style={{ backgroundColor: cor }}
				>
					{props.word}
				</a>
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === "bottom" ? "center top" : "center bottom",
							}}
						>
							<Paper elevation={3}>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList
										autoFocusItem={open}
										id="menu-list-grow"
										onKeyDown={handleListKeyDown}
									>
										<MenuItem disabled> {props.type}</MenuItem>
										{replacements.slice(0, 5).map((c) => (
											<div key={c.offset}>
												<MenuItem onClick={handleClose}>{c.value}</MenuItem>
											</div>
										))}
										{urls &&
											props.url.map((r) => (
												<div key={r.offset}>
													<MenuItem
														onClick={() => window.open(r.value, "_blank")}
													>
														Ver explicação
													</MenuItem>
												</div>
											))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</>
		);
	else return <></>;
}
