import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { apiSecure } from '../../domain/api'

export default function ThemesGenre(props, { parentCallback }) {
  const classes = useStyles()

  const [genre, setGenre] = useState([])

  useEffect(() => {
    getReasons()
    function getReasons() {
      apiSecure
        .get('/Theme/l/genre?typeId=' + props.typeId)
        .then(function (response) {
          setGenre(response.data)
        })
        .catch(function (error) {})
    }
  }, [props.typeId, props.requestList])

  if (genre.length > 0)
    return (
      <Autocomplete
        id="combo-box-demo"
        options={genre}
        getOptionLabel={(option) => option.description}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.buttons}
            label="Gênero textual"
            variant="outlined"
          />
        )}
        onChange={(event, newValue) => {
          if (newValue != null) props.onSubmit(newValue.id)
        }}
      />
    )
  else return <></>
}

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginBottom: '4%',
    '@media (max-width: 958px)': {
      flexDirection: 'column',
    },
  },
}))
