import styled, { keyframes } from "styled-components";

interface CardProps {
  show: boolean;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Content = styled.div`
.titles {
  color: "var(--white)";
  text-Align: "center";
  
  @media(max-width: 1280){
    margin: "10px";
  }
`;

export const Container = styled.div`
  margin: 0% 1% 1% 1%;
  padding: 0.5rem 5px;
  background: var(--white);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: flex;
  max-width: 98%;
  flex-wrap: wrap;

  h3 {
    display: block;
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-bottom: 1rem;

  @media (max-width: 1280px) {
    & > div {
      flex-basis: 100%;
    }
  }
`;
export const Col1 = styled.div`
  width: 10%;
  background-color: var(--blue-light);
  color: var(--white);
  font-size: 14pt;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0.28rem 0 0 0.28rem;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1280px) {
    border-radius: 0.28rem 0.28rem 0 0;
  }
`;

export const Col6 = styled.div`
  width: 50%;
  border: solid 1px var(--blue-light);
  background-color: var(--white);
  color: var(--blue-light);
  font-size: 14pt;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0 0.28rem 0.28rem 0;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1280px) {
    width: 75%;
    border-radius: 0 0 0.28rem 0.28rem;
  }
`;

export const Col3 = styled.div`
  width: 22.4%;
  border: solid 1px var(--blue-light);
  background-color: var(--white);
  color: var(--blue-light);
  font-size: 14pt;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0 0.28rem 0.28rem 0;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1280px) {
    width: 50%;
    margin-bottom: 20px;
    border-radius: 0 0 0.28rem 0.28rem;
  }
`;

export const Col12 = styled.div`
  width:100%;
  color: var(--red);
  text-transform: uppercase;
  justify-content: center;
  display:flex;
  margin: 10px 0 15px 0; 
  font-weight:bold;
  font-size:14pt;
`

export const Col4 = styled.div`
  width: 32%;
  border: solid 1px var(--blue-light);
  background-color: var(--white);
  color: var(--blue-light);
  font-size: 14pt;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0 0.28rem 0.28rem 0;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 1280px) {
    width: 50%;
    margin-bottom: 20px;
    border-radius: 0 0 0.28rem 0.28rem;
  }
`;

export const Col3Competence = styled.div`
  flex-basis: calc(33.333% - 30px);
  border-radius: 0 0.28rem 0.28rem 0;
  padding: 5px;
  display: flex;
  justify-content: center; 
  align-items: center; 
  text-align: center; 
  width: 100%; 
  
  > * { 
    flex-grow: 1; 
    text-align: center; 
  }

  @media (max-width: 1280px) {
    flex-basis: 100%;
    margin-bottom: 20px;
    border-radius: 0 0 0.28rem 0.28rem;
    flex-direction: column;
  }
`;

export const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Gap = styled.div`
  flex-basis: 20px;
  flex-grow: 0;
`;

export const Card = styled.div`
  box-sizing: border-box;
  flex-basis: 0;
  flex-grow: 1;
  margin: 5px;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--blue-light);
  border-radius:0.4rem;
  @media (max-width: 1280px) {
    max-width: 94vw !important;
  }
`;

export const CardUpper = styled.div`
  display: flex;
  color: var(--white);
  background: var(--blue-light);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 5px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 14pt;
  font-weight: bold;

`;

export const CardBottom = styled.div`
  display: flex;
  background: var(--white);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 5px;
  justify-content: center;
  align-items: center;
  min-height: 450px;
`;

export const CardGraph = styled.div`
  display: flex;
  background: var(--white);
  border-radius: 0.25rem;
  padding: 5px;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  width: 100%;
  flex-grow:1;
`;

export const CardQtdAlunos = styled.div<CardProps>` 
  text-transform: uppercase;
  font-size: 100pt;
  font-weight: bold;
  color: var(--blue-light);
  animation: ${fadeIn} 1s ease-in-out ${(props) => (props.show ? 'forwards' : 'none')}; 
`;