import React from "react";
import { Topbar } from "../../../component/Topbar";
import { makeStyles } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { Typography } from "@material-ui/core";
import { Accordion, AccordionItem } from "react-sanfona";

const breakpoints = createBreakpoints({});
function CorrectionRules() {
  const classes = useStyles();

  return (
    <>
      <Topbar profile="8" menu="rules" />
      <div
        style={{
          fontWeight: "bold",
          margin: "1% 1% 0 1%",
          textAlign: "center",
          backgroundColor: "var(--blue-light)",
          color: "var(--white)",
          padding: "1%",
          borderTopLeftRadius: "0.25rem",
          borderTopRightRadius: "0.25rem",
        }}
      >
        <h4>INSTRUÇÕES DE ESCRITA DOS</h4>
        <h1>GÊNEROS TEXTUAIS</h1>
      </div>
      <div
        style={{
          textTransform: "uppercase",
          backgroundColor: "var(--white)",
          color: "var(--blue-light)",
          margin: "0 1% 1% 1%",
          padding: "1%",
          borderBottomLeftRadius: "0.25rem",
          borderBottomRightRadius: "0.25rem",
        }}
      >
        <center>
          <h5>
            Clique em um dos gêneros para visualizar o modelo de texto a ser
            escrito
          </h5>
        </center>
        <hr style={{ margin: "1%", color: "var(--blue-light)" }}></hr>
        <Accordion>
          <AccordionItem
            title="Artigo de Opinião"
            className={classes.Accordion}
          >
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Artigo de Opinião"
                src="https://www.youtube.com/embed/AnfN1m8ftgQ"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>

          <AccordionItem title="Carta Aberta" className={classes.Accordion}>
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Carta Aberta"
                src="https://www.youtube.com/embed/N94Ei-eC_z8"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>

          <AccordionItem
            title="Carta Argumentativa"
            className={classes.Accordion}
          >
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Carta Aberta"
                src="https://www.youtube.com/embed/HHe_ZaHg1L0"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>

          <AccordionItem
            title="Carta de solicitação"
            className={classes.Accordion}
          >
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Carta de solicitação"
                src="https://www.youtube.com/embed/rC23JARLV_M"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>

          <AccordionItem title="Carta do leitor" className={classes.Accordion}>
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Carta do leitor"
                src="https://www.youtube.com/embed/IGJRmXJrrKo"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>

          <AccordionItem title="Carta pessoal" className={classes.Accordion}>
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Carta pessoal"
                src="https://www.youtube.com/embed/rInMTZUEJRg"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>

          <AccordionItem title="Conto" className={classes.Accordion}>
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Conto"
                src="https://www.youtube.com/embed/u6TlxwuN5Fo"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>

          <AccordionItem title="Crônica" className={classes.Accordion}>
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Resumo"
                src="https://www.youtube.com/embed/kvaJ99i6ReY"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>

          <AccordionItem
            title="Dissertativo-argumentativo"
            className={classes.Accordion}
          >
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Dissertativo-argumentativo"
                src="https://www.youtube.com/embed/qiE57CH-X-Q"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>

          <AccordionItem title="Editorial" className={classes.Accordion}>
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Resumo"
                src="https://www.youtube.com/embed/TG2JCvj7uz0"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>
          <AccordionItem title="Fábula" className={classes.Accordion}>
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Resumo"
                src="https://www.youtube.com/embed/4n9B3lK7U-Y"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>
          <AccordionItem title="Relato" className={classes.Accordion}>
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Relato"
                src="https://www.youtube.com/embed/LRj8N5rX1sc"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>
          <AccordionItem title="Resumo" className={classes.Accordion}>
            <div className={classes.categories}>
              <iframe
                className={classes.embed}
                height="500px"
                title="Resumo"
                src="https://www.youtube.com/embed/H63mitCPywA"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionItem>
        </Accordion>

        {/* <div class={classes.container}>
				<Button variant="contained" color="primary" className={classes.buttons}>
					Atividade Escolar
				</Button>
				<Button variant="contained" color="primary" className={classes.buttons}>
					Enem
				</Button>
				<Button variant="contained" color="primary" className={classes.buttons}>
					Vestibular
				</Button>
			</div> */}
      </div>
    </>
  );
}

export default CorrectionRules;

const useStyles = makeStyles((theme) => ({
  Accordion: {
    padding: 10,
    textAlign: "center",
    backgroundColor: "var(--background)",
    margin: "1% 1%",
    borderRadius: "0.25rem",
    "&:hover": {
      backgroundColor: "#f2f1f0",
    },
  },
  embed: {
    [breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
    [breakpoints.only("md")]: {
      flexDirection: "column",
      margin: "auto",
      width: "100%",
    },
    width: "60%",
    margin: 10,
  },
  categories: {
    margin: "auto",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "90%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "column",
      width: "90%",
    },
    "@media (min-width: 1280px)": {
      flexDirection: "row",
      width: "90%",
      justifyContent: "center",
    },
    marginTop: 10,
  },
  buttons: {
    margin: 10,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "column",
      width: "50%",
    },
    "@media (min-width: 1280px)": {
      flexDirection: "column",
      width: "50%",
    },
  },

  h5: {
    textTransform: "uppercase",
  },
}));
