import axios from 'axios'
import { ResetTokenAndReattemptRequest } from 'domain/auth/AuthService'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const apiSecure = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('Token'),
    'Content-Type': 'application/json',
  },
})

export const apiDiagnostic = axios.create({
  baseURL: process.env.REACT_APP_DIAGNOSTIC_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiDiagnostic.interceptors.request.use(config => {
  const token = localStorage.getItem('Token');
  const apiKey = process.env.REACT_APP_DIAGNOSTIC_API_KEY
  if (token) {
    config.headers.ApiKey = apiKey;
  }
  //console.log('Iniciando Requisição', config);
  return config;
}, error => {
  return Promise.reject(error);
});

export const languageApi = axios.create({
  // BASE PRODUÇÃO
  //baseURL: 'https://redacaoparana.pr.gov.br/v2/', 
  baseURL: 'http://localhost:8081/v2/', 
  // BASE LOCAL
  //baseURL: 'http://localhost:50352/', 
  headers: {
    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
  },
})

export default api

apiSecure.interceptors.response.use(
  function (response) {
    if (navigator.onLine) {
      console.log('O navegador está online.');
    } else {
      console.log('O navegador está offline.');
    }
    return response;
  },
  function (error) {
    const access_token = localStorage.getItem('Token');
    const expiresAt = localStorage.getItem('ExpiresAt');

    // Verifica se o token expirou
    if (expiresAt && new Date().getTime() > parseInt(expiresAt)*1000) {
      console.log(expiresAt)
      console.error('Token expired. Attempting to refresh token and reattempt request.');
      //window.location.href =  '/logout';
      return ResetTokenAndReattemptRequest(error);
    }

    // Trata resposta com erro 401 que não é por token expirado
    if (error.response.status === 401 && access_token) {
      console.error('Error 401 received. Attempting to refresh token and reattempt request.');
      //window.location.href =  '/logout';
      return ResetTokenAndReattemptRequest(error);
    }

    // Loga outros erros
    console.error('API request failed:', error);
    return Promise.reject(error);
  }
);

