import React, { Component } from "react";
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts";

class GaugeChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          type: 'radialBar',
          offsetY: -20
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: '97%',
              margin: 5,
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '70px',
                fontWeight: 'bold',
                color:'#0F4665',
                formatter: function (val) {
                  return val;
                } 
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          colors: ['#0F4665'],
        },
        labels: ['Média das notas'],
      },
      series: [props.mediaNotas],
    };
  }


  componentDidUpdate(prevProps) {
    if (prevProps.mediaNotas !== this.props.mediaNotas) {
      const color = this.props.mediaNotas < 60 ? '#CB0000' : '#10C300';
  
      this.setState((prevState) => ({
        series: [this.props.mediaNotas],
        options: {
          ...prevState.options,
          fill: {
            colors: [color],
          },
        },
      }));
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height={430}
        />
      </div>
    );
  }
}

GaugeChart.propTypes = {
  mediaNotas: PropTypes.number,
};

export default GaugeChart;
