import styled from 'styled-components'

export const Container = styled.div`
margin: 15px;
padding: 20px;
background: var(--white);
border-radius: 0.25rem;
border-width: 0.15rem;
border-style: solid;
border-color: var(--background);
width: 31.3%;
cursor: pointer;
height: 90%;

button{
    
    height: 2rem;
    width: 6rem;
    margin: 0.2rem;
}

@media(max-width: 900px){
    width: 100%;
}

&:hover{
    filter: brightness(0.95);
    }
`

export const Content = styled.div`
    margin: 1.5rem;
    padding: 1rem;
    background: var(--white);
    border-radius: 0.25rem;
    display: flex;
    max-width: 100%;    
    flex-wrap: wrap;
`