/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { apiSecure } from '../../domain/api'

export default function Schools(props) {
  const classes = useStyles()
  const [school, setSchool] = useState([])

  useEffect(() => {
    getReasons()
    function getReasons() {
      apiSecure
        .get('/TeacherSchool/school')
        .then(function (response) {
          setSchool(response.data)
        })
        .catch(function (error) {})
    }
  }, [])

  return (
    <Autocomplete
      id="combo-box-demo"
      multiple
      options={school}
      getOptionLabel={(option) => option.description}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.buttons}
          label="Escolas"
          variant="outlined"
        />
      )}
      onChange={(event, newValue) => {
        if (newValue != null) props.onSubmit(newValue)
      }}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: '4%',
    '@media (max-width: 958px)': {
      flexDirection: 'column',
    },
  },
}))
