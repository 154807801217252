import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled";

export default function Loading({ isLoading, txt }) {
  if (!isLoading) return null;
  return (
    <Container>
      <div className="loading-spinner" />
      <div className="loading-text">
        {txt}
        <span className="loading-dots">...</span>
      </div>
    </Container>
  );
}

Loading.defaultProps = {
  isLoading: false,
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
};
