import styled, { keyframes } from "styled-components";

export const blink = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  position: fixed;
  width: 100vw; /* Garante que ocupa 100% da largura da viewport */
  height: 100%; /* Garante que ocupa 100% da altura da viewport */
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center; /* Centraliza o texto dentro da div */

  .loading-spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${spin} 2s linear infinite;
  }

  .loading-text {
    margin-top: 16px;
    font-size: 1.5rem;
    max-width: 90%; /* Evita que o texto ultrapasse a tela */
    word-wrap: break-word; /* Garante quebra de linha, se necessário */

    @media (min-width: 768px) {
      font-size: 2rem; /* Aumenta o tamanho da fonte em telas maiores */
      max-width: 60%; /* Ajusta o tamanho máximo para telas maiores */
    }

    @media (min-width: 1024px) {
      font-size: 1.5rem; /* Aumenta ainda mais para telas grandes */
      max-width: 50%; /* Limita a largura do texto */
    }
  }

  .loading-dots {
    animation: ${blink} 1s infinite;
  }
`;
