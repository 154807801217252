import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

:root{
    --blue-light: #0F4665;
    --blue-dark: #0A2D42;
    --blue-light2 : #1A78B0;
    --blue-light3 : #1A79AF;
    --background: #E5E4E2;

    --green: #21B534;
    --green2: #39a83b;

    --blue: #2693ff;

    --red: #FF0800;
    --background-blue: #d5e6fb;
    --yellow: #ffff00;
    --white: #ffffff;
}

body, input, textarea,button{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    }   


html{
    @media(max-width: 1080px){
        font-size: 93.75%;
    }

    @media (max-width: 720px){
        font-size: 87.5%;
    }

}


h1, h2, h3, h4, h5, h6, strong{
    font-weight: 600;
}

html, body, #root, .App {
  height: 100%;
}

body{
    background: var(--background);
    -webkit-font-smoothing: antialiased;
    
}


button{
    cursor: pointer;
    border-radius: 0.25rem; 
    &:hover{
        filter: brightness(1.2)
    }
}

[disabled]{
    opacity: 0.6;
    cursor: not-allowed;
}

.floatingActionButton{
    position: fixed;
    bottom: 100px;
    right: 1%;
    cursor: pointer;
    width: 11rem;
    height: 4rem;
    background: var(--green);
    border-width: 0;
    border-radius: 0.25rem;
    color: var(--white);
    font-size: 1rem;
    font-weight: 900;
    padding: 5px;
    z-Index: 100000;

    &:hover{
        filter: brightness(1.2);
    }

    @media (max-width: 600px){
        z-Index: 10;
    }
}

`;
