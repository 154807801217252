import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

class BarWithMarkersChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Pontuação",
          type: "column",
          data: this.formatSeriesData(props.turmas),
        },
      ],
      options: this.getOptions(props.tituloGrafico, props.max_Score),
      chartWidth: "475", // Define um valor padrão para a largura
    };
  }

  componentDidMount() {
    this.updateChartWidth();
    window.addEventListener('resize', this.updateChartWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateChartWidth);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.turmas) !== JSON.stringify(this.props.turmas) ||
      prevProps.tituloGrafico !== this.props.tituloGrafico ||
      prevProps.max_Score !== this.props.max_Score
    ) {
      this.setState({
        series: [
          {
            name: "Pontuação",
            type: "column",
            data: this.formatSeriesData(this.props.turmas),
          },
        ],
        options: this.getOptions(this.props.tituloGrafico, this.props.max_Score),
      });
    }
  }

  updateChartWidth = () => {
    const screenWidth = window.innerWidth;
    const chartWidth = screenWidth < 1240 ? '350' : '475';
    this.setState({ chartWidth });
  };

  formatSeriesData = (turmas) => {
    return turmas.map((turma) => ({
      x: turma.nome,
      y: turma.pontuacao,
      goals: [
        {
          value: this.props.max_Score,
          name: "Meta",
          strokeWidth: 5,
          strokeColor: "#10C300",
        },
      ],
    }));
  };

  getOptions = (tituloGrafico, max_Score) => {
    return {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: ["#0F4665"],
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          const goals = opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].goals;
          return goals && goals.length ? `${val} / ${goals[0].value}` : val;
        },
      },
      xaxis: {
        max: max_Score,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          }
        }
      },
    };
  };

  render() {
    const { chartWidth } = this.state;
    const titleContainerStyle = {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 'bold',
      fontSize: '14px',
      color: '#263238',
      textAlign: 'center',
      textJustify: 'inter-word',
      marginTop: '0',
      margin: '0 5px',
      paddingTop: '10px',
    };

    return (
      <div id="chart">
        <div style={titleContainerStyle}>
          {this.props.tituloGrafico.split('\n').map((linha, index) => (
            <div key={index} style={{ width: '100%' }}>
              {linha}
            </div>
          ))}
        </div>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={250}
          width={chartWidth}
        />
      </div>
    );
  }
}

BarWithMarkersChart.propTypes = {
  turmas: PropTypes.arrayOf(
    PropTypes.shape({
      nome: PropTypes.string.isRequired,
      pontuacao: PropTypes.number.isRequired,
    })
  ).isRequired,
  tituloGrafico: PropTypes.string.isRequired,
  max_Score: PropTypes.number.isRequired,
};

export default BarWithMarkersChart;
