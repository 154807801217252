import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Topbar } from "component/Topbar";
import { languageApi, apiSecure } from "domain/api";
import {
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Correction from "./component/Correction";
import ErrorList from "./component/ErrorList";
import qs from "querystring";
import CorrectionHistory from "./component/CorrectionHistory";
import DialogActions from "@material-ui/core/DialogActions";
import { GetEssayDetails } from "domain/essayStudent/service";
import { useHistory } from "react-router-dom";
import loadingLogo from "../../../component/images/loader3.gif";
import { GetReviewByToken } from "domain/review/service";
import {
  GetDetails as GetThemeDetails,
  GetGenre,
  GetType,
  GetMotivatingText,
} from "domain/theme/service";
import Countdown from "react-countdown";
import Modal from "component/Modal";
import InfoNote from "component/InfoNote";
import localforage from "localforage";
import AxiosRequestBuilder from "../../../services/axiosRequestBuilder";
import Loading from "../../../component/_Loading";
import { EssaySave, initialEssaySave } from "../../../_Types/essaySave";
import Toast from "../../../component/_Toast";

localforage.config({
  driver: localforage.INDEXEDDB,
  name: "Essay",
  version: 1.0,
  storeName: "OfflineEssay",
  description: "Essay offline storage",
});
interface Props {
  match: {
    params: {
      token: string;
    };
  };
  creatorId: number;
  alunoId: number;
}
interface Theme {
  id: number;
  description: string;
  themeTypeId: number;
  themeGenreId: number;
  title: string;
}
interface Essay {
  id: number;
  text: string;
  title: string;
  status: number;
  themeId: number;
  statusDescription: string;
  minAmountWords: number;
  maxAmountWords: number;
  selfCreated: boolean;
  creator: string;
  creatorId: number;
  details: string;
  rules: [
    {
      id: number;
      description: string;
    }
  ];
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface Review {
  reviewText: string;
  result: any;
}
const Essay: React.FC<Props> = (props) => {
  const [open_, setOpen_] = useState(false);
  const [failCorrection, setFailCorrection] = useState(false);
  const [saveStatus, setSaveStatus] = useState<boolean | null>(null);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  // const [saving, setSaving] = useState(false);
  // const [success_, setSuccess_] = useState(null || Boolean);
  // const [isLoading, setIsLoading] = useState(false);
  const [textError, setTextError] = useState(false);
  const classes = useStyles();
  const [text, setText] = useState("");
  const [textBanco, setTextBanco] = useState("");
  const [lastText, setLastText] = useState<string>("");
  const [title, setTitle] = useState("");
  const [essayDetails, setEssayDetails] = useState<Essay>();
  const [reviewDetails, setReviewDetails] = useState<Review>();
  const [json, setJson] = useState<any>({});
  const [score, setScore] = useState<number>(0);
  const [run, setRun] = useState(false);
  const [save, setSave] = useState(false);
  const [isAutoSave, setIsAutoSave] = useState(false);
  const [send, setSend] = useState(false);
  const [open, toggleOpen] = useState(false);
  const [theme, setTheme] = useState<Theme>();
  const [genre, setGenre] = useState();
  const [type, setType] = useState();
  const [motivatingTexts, setMotivatingTexts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonTextDisabled, setButtonTextDisabled] = useState(true);
  const [wordCount, setWordCount] = useState(0);
  const [descriptores, setDescriptors] = useState([]);
  const [paragraphCount, setparagraphCount] = useState(0);
  const [loading, setloading] = React.useState(false);
  const [loadingCorrection, setLoadingCorrection] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const history = useHistory();
  const [disableCorrection, setDisableCorrection] = useState(false);
  const [timer, setTimer] = useState(Date.now());
  const [IsErrorTimer, setIsErrorTimer] = useState(false);
  const [errorTimer, setErrorTimer] = useState(Date.now());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalErrorVisible, setIsModalErrorVisible] = useState(false);
  const [lastValue, setLastValue] = useState(text);
  const [buttonTitle, setButtonTitle] = useState("SALVAR RASCUNHO");
  const [textSaved, setTextSaved] = useState("");
  const [lastSavedText, setLastSavedText] = useState("");
  const [isFromDatabase, setIsFromDatabase] = useState(false);
  const [autoSave, setAutoSave] = useState(false);
  const [isInsertingTab, setIsInsertingTab] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [offline, setOffline] = useState(!navigator.onLine);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCorrecao, setIsCorrecao] = useState(false);
  const [isPaste, setIsPaste] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  function sendMail(props: Props) {
    // console.log("Passei aqui");
    const authToken = localStorage.getItem("Token");
    let titulo = title;
    if (titulo === "") {
      titulo = " ";
    }

    try {
      // console.log(
      //   "sendMail ",
      //   props.match.params.token,
      //   " ",
      //   essayDetails?.id,
      //   " ",
      //   authToken,
      //   " "
      // );
      apiSecure.post(
        "/Essay/student/sendMail/" +
          essayDetails?.id +
          "/" +
          props.match.params.token +
          "/" +
          titulo +
          "/" +
          text,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
    } catch (error) {
      console.error("Erro ao enviar as informações:", error);
    }
  }

  const handleModalConfirm = () => {
    history.push("/student/SearchByToken");
  };

  function handleType(props: any) {
    const result = props.find((item: any) => item.id === theme?.themeTypeId);
    setType(result.description);
  }

  function handleGenre(props: any) {
    const result = props.find((item: any) => item.id === theme?.themeGenreId);
    setGenre(result.description);
  }

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  useEffect(() => {
    if (essayDetails && essayDetails.text.length > 0) {
      setIsFromDatabase(true);
      updateTextFromDatabase(essayDetails.text);
      setTitle(essayDetails.title);
      if (essayDetails.status === 4 || essayDetails.status === 6) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [essayDetails]);

  useEffect(() => {
    const goOnline = () => {
      console.log(
        "A conexão com o navegador foi reestabelecida. Verificando por rascunhos..."
      );
      setOffline(false);
      saveDraft();
    };

    const goOffline = () => {
      console.log("Navegador está offline.");
      setOffline(true); // Atualiza o estado para offline
    };

    // Adicionar listeners para mudanças de status de conexão
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    // Limpeza: remover os event listeners quando o componente for desmontado
    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  useEffect(() => {
    if (run === true) {
      setRun(false);
      setDisableCorrection(true);
      setTimer(Date.now() + 30000);
      checkText();
      GetDescriptors();
      setIsCorrecao(true);
    }

    function GetDescriptors() {
      apiSecure
        .get(
          "/Descriptor/type/" +
            theme?.themeTypeId +
            "/genre/" +
            theme?.themeGenreId
        )
        .then((response) => {
          setDescriptors(response.data);
          //console.log(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    function checkText() {
      if (text === "" || text === undefined || text === null) {
        setTextError(true);
      } else {
        if (!loadingCorrection) {
          setSuccess(false);
          setLoadingCorrection(true);
        }
        setTextError(false);
        apiSecure
          .post("/Essay/student/V2/correction", {
            EssayId: essayDetails?.id,
            Text: text,
            Title: title,
            Status: 3,
          })
          .then(function (response: any) {
            setTimeout(() => {
              setJson(response.data.languageToolJson);
              setSuccess(true);
              setLoadingCorrection(false);
              setOpenDialog(false);
              setScore(response.data.score);
              if (send) {
                sendEssay();
              }
              toggleOpen(true);
            }, 5000);
          })
          .catch((err) => {
            setLoadingCorrection(false);
            setRun(false);
            setOpenDialog(false);
            setFailCorrection(true);
          });
      }
    }

    function sendEssay() {
      //console.log('SendEssay');
      apiSecure
        .put("Essay/student/" + essayDetails?.id + "/finish")
        .then(function (response) {
          setSend(false);
          history.push("/student/home");
        })
        .catch(function (error) {});
    }
  }, [run]);

  const handleButtonClick = async (essayx: EssaySave) => {
    if (essayx) {
      try {
        setIsLoadingSave(true);
        const axiosRequestBuilder = new AxiosRequestBuilder();
        const response = await axiosRequestBuilder
          .setMethod("post")
          .setUrl(`/Essay/student/SaveEssay`)
          .setResponseType("json")
          .setTimeout(15000)
          .setBody<EssaySave>(essayx)
          .send<EssaySave>();
        if (response) {
          setSaveStatus(true);
          setSave(false);
          setSuccess(true);
          //console.log(isAutoSave);
          setLastSavedText(text);
          if (!isAutoSave) {
            setIsModalVisible(true);
            sendMail(props);
          }
          setloading(false);
          setIsAutoSave(false);
          setIsErrorTimer(false);
        }
      } catch (error: unknown) {
        console.log(error, "error");
        setSaveStatus(false);
        if (
          typeof error === "object" &&
          error !== null &&
          "response" in error
        ) {
          const { response } = error as { response?: { status: number } };
          console.log(response, "response");
          if (response && response.status !== 401) {
            if (!isAutoSave) {
              setIsModalErrorVisible(true);
              setSave(false);
              setButtonDisabled(true);
              setButtonTextDisabled(true);
              setButtonTitle("SALVAR RASCUNHO");
            }
            setloading(false);
          }
        }
      } finally {
        await sleep(1500);
        setIsLoadingSave(false);
      }
    }
  };

  const saveEssay = () => {
    if (essayDetails?.status !== 4 && essayDetails?.status !== 6) {
      setErrorTimer(Date.now() + 10000);
      setIsErrorTimer(true);
      let status = 2;
      if (essayDetails?.status === 5) {
        status = 5;
      }

      if (!loading) {
        setSuccess(false);
        setloading(true);
      }

      const essay_ = {
        essayId: essayDetails?.id,
        text: text,
        title: title,
        status: status,
      };

      handleButtonClick(essay_);
    }
  };

  // useEffect(() => {
  //   if (save === true) {
  //     saveEssay();
  //   }

  //   function saveEssay() {
  //     if (essayDetails?.status !== 4 && essayDetails?.status !== 6) {
  //       setErrorTimer(Date.now() + 10000);
  //       setIsErrorTimer(true);
  //       let status = 2;
  //       if (essayDetails?.status === 5) {
  //         status = 5;
  //       }

  //       if (!loading) {
  //         setSuccess(false);
  //         setloading(true);
  //       }

  //       const essay_ = {
  //         essayId: essayDetails?.id,
  //         text: text,
  //         title: title,
  //         status: status,
  //       };

  //       handleButtonClick(essay_);

  // const apiCall = apiSecure.post("/Essay/student/SaveEssay", {
  //   EssayId: essayDetails?.id,
  //   Text: text,
  //   Title: title,
  //   Status: status,
  // });

  // const timeout = new Promise((_, reject) =>
  //   setTimeout(() => reject(new Error("Request timed out")), 10000)
  // );

  // Promise.race([apiCall, timeout])
  //   .then(function (response) {
  //     setSave(false);
  //     setSuccess(true);
  //     //console.log(isAutoSave);
  //     setLastSavedText(text);
  //     if (!isAutoSave) {
  //       setIsModalVisible(true);
  //       sendMail(props);
  //     }
  //     setloading(false);
  //     setIsAutoSave(false);
  //     setIsErrorTimer(false);
  //   })
  //   .catch(function (error) {
  //     if (!isAutoSave) {
  //       setIsModalErrorVisible(true);
  //       setSave(false);
  //       setButtonDisabled(true);
  //       setButtonTextDisabled(true);
  //       setButtonTitle("SALVAR RASCUNHO");
  //     }
  //     console.error(error);
  //     setloading(false);
  //   });
  //     }
  //   }
  // }, [save]);

  useEffect(() => {
    setWordCount(0);
    //console.log(text.length);
    if (text.length > 0) {
      setWordCount(
        text.trim().split(/\b[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{1,50}\b/)
          .length - 1
      );
      setButtonDisabled(false);
      setButtonTextDisabled(false);
    } else {
      setButtonDisabled(true);
      setButtonTextDisabled(true);
    }
  }, [text]);

  useEffect(() => {
    setparagraphCount(0);
    if (text.length > 0) {
      setparagraphCount(text.trim().replace(/\n$/gm, "").split(/\n/).length);
    }
  });

  useEffect(() => {
    if (text.length > 0) {
      //console.log(text.split(/\r\n|\r|\n/).length);
    }
  }, [text]);

  const handleClose = () => {
    toggleOpen(false);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
  };

  const handleSend = () => {
    setOpenDialog(true);
  };

  const handleDialogConfirm = () => {
    setSend(true);
    setRun(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleInput = (event: React.FormEvent<HTMLDivElement>) => {
    const target = event.target as HTMLInputElement;
    setText(target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const updateTextFromDatabase = (newText: string) => {
    setIsFromDatabase(true);
    setText(newText);
  };

  useEffect(() => {
    if (isFromDatabase) {
      setLastText(text);
      setIsFromDatabase(false);
    } else if (text.length - lastText.length > 1 && !isInsertingTab) {
      setText(lastText);
    } else {
      setLastText(text);
      setIsInsertingTab(false);
    }

    if (isMobile) {
      const preventCopyPaste = (event: Event) => event.preventDefault();

      window.addEventListener("copy", preventCopyPaste);
      window.addEventListener("paste", preventCopyPaste);

      return () => {
        window.removeEventListener("copy", preventCopyPaste);
        window.removeEventListener("paste", preventCopyPaste);
      };
    }
  }, [text]);

  const handleDragOver = (event: React.DragEvent<HTMLTextAreaElement>) => {
    setIsPaste(true);
    event.preventDefault();
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    setIsPaste(true);
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLInputElement>) => {
    setIsPaste(true);
    event.preventDefault();
    setText("");
    setLastValue("");
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [text]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Tab" && inputRef.current) {
      event.preventDefault();
      setIsInsertingTab(true);
      const start = inputRef.current.selectionStart ?? 0;
      const end = inputRef.current.selectionEnd ?? 0;
      const beforeTab = text.substring(0, start);
      const afterTab = text.substring(end);
      const newText = `${beforeTab}        ${afterTab}`;

      setText(newText);

      setTimeout(() => {
        if (inputRef.current) {
          const newPos = start + 8;
          inputRef.current.selectionStart = newPos;
          inputRef.current.selectionEnd = newPos;
        }
      }, 0);
    }
  };

  const handleInsertParagraph = () => {
    if (inputRef.current) {
      const start = inputRef.current.selectionStart ?? 0;
      const end = inputRef.current.selectionEnd ?? 0;
      const beforeText = text.substring(0, start);
      const afterText = text.substring(end);
      const newText = `${beforeText}        ${afterText}`;
      setText(newText);

      setIsInsertingTab(true);
      setTimeout(() => {
        setIsInsertingTab(false);
        if (inputRef.current) {
          inputRef.current.selectionStart = inputRef.current.selectionEnd =
            start + 8; // Move o cursor após as novas linhas
        }
      }, 0);
    }
  };

  const sendDataToServer = (textToSend: string) => {
    setLastSavedText(textToSend);
    setSave(true);
    setIsFirstLoad(true);
  };

  const autoSendDataToServer = (textToSend: string) => {
    setIsAutoSave(true);
    setLastSavedText(textToSend);
    setSave(true);
    //console.log(lastSavedText);
    //console.log('autosave');
  };

  function saveDraft() {
    saveEssay();
    if (navigator.onLine) {
      localforage
        .getItem("draft")
        .then((draft) => {
          //Se houver draft no localforage
          if (draft && typeof draft === "string") {
            sendDataToServer(draft);
            setButtonTextDisabled(true);
          } else {
            sendDataToServer(text);
          }
        })
        .catch((err) => {
          console.error("Erro ao recuperar o rascunho:", err);
        });
    } else {
      // Salvando o rascunho localmente quando está offline
      localforage
        .setItem("draft", text)
        .then(() => {
          console.log("Rascunho salvo localmente porque você está offline.");
          setButtonTextDisabled(true);
          setButtonTitle("RASCUNHO SALVO LOCALMENTE");
        })
        .catch((err) => {
          console.error("Erro ao salvar o rascunho localmente:", err);
        });
    }
  }

  function autoSaveDraft() {
    if (navigator.onLine) {
      localforage
        .getItem("draft")
        .then((draft) => {
          //Se houver draft no localforage
          if (draft && typeof draft === "string") {
            autoSendDataToServer(draft);
          } else {
            autoSendDataToServer(text);
          }
        })
        .catch((err) => {
          console.error("Erro ao recuperar o rascunho:", err);
        });
    } else {
      // Salvando o rascunho localmente quando está offline
      localforage
        .setItem("draft", text)
        .then(() => {
          console.log("Rascunho salvo localmente porque você está offline.");
        })
        .catch((err) => {
          console.error("Erro ao salvar o rascunho localmente:", err);
        });
    }
  }

  useEffect(() => {
    if (autoSave) {
      //console.log(text);
      //console.log(lastSavedText)
      const interval = setInterval(() => {
        if (
          !loading &&
          !loadingCorrection &&
          text !== lastSavedText &&
          text !== ""
        ) {
          autoSaveDraft();
        }
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [autoSave, text, lastSavedText, loading, loadingCorrection, disabled]);

  useEffect(() => {
    if (text !== lastSavedText) {
      setButtonTextDisabled(false);
      setButtonTitle("SALVAR RASCUNHO");
    }
  }, [text, lastSavedText]);

  // console.log(saveStatus, "saveStatus");

  return (
    <>
      {failCorrection && (
        <Toast
          setOpen={setFailCorrection}
          msg={"NÃO FOI POSSÍVEL CORRIGIR SUA REDAÇÃO! TENTE NOVAMENTE."}
          open={failCorrection}
          severity={"error"}
          href={null}
        />
      )}
      {loadingCorrection && (
        <Loading isLoading txt="PROCESSANDO" />

        // <>
        //   {/* LOADING */}
        //   <div
        //     style={{
        //       backgroundColor: "var(--background)",
        //       width: "100%",
        //       height: "100vh",
        //       zIndex: "5000",
        //       position: "fixed",
        //       cursor: "pointer",
        //       overflow: "hidden",
        //     }}
        //   >
        //     <div
        //       id="dvLoading"
        //       style={{
        //         position: "fixed",
        //         left: "50%",
        //         top: "50%",
        //         transform: "translate(-50%, -50%)",
        //       }}
        //     >
        //       <center>
        //         <img src={loadingLogo} alt="CARREGANDO" width="15%" />
        //       </center>
        //     </div>
        //   </div>
        // </>
      )}
      <Topbar menu="home" profile="8" />

      <GetEssayDetails
        token={props.match.params.token}
        id={props.match.params.token}
        callback={(e: any) => setEssayDetails(e)}
      />
      {essayDetails && (
        <>
          <GetThemeDetails
            id={essayDetails.themeId}
            callback={(e: any) => setTheme(e)}
          />
        </>
      )}

      {theme && theme.id > 0 && (
        <GetMotivatingText
          id={theme.id}
          callback={(e: any) => setMotivatingTexts(e)}
        />
      )}

      {theme && theme.themeTypeId && (
        <>
          <GetGenre callback={(e: any) => handleGenre(e)} />
          <GetType callback={(e: any) => handleType(e)} />
        </>
      )}

      {essayDetails && (
        <div
          className={classes.container}
          style={{ margin: 1, justifyContent: "center", display: "flex" }}
        ></div>
      )}

      <div className={classes.container}>
        {/* LEFT - REDAÇÃO */}
        <div
          style={{ borderRadius: "0.25rem" }}
          className={classes.inputArea}
          onContextMenu={(e) => e.preventDefault()}
        >
          {/* TITULO */}
          <label htmlFor="outlined-multiline-static" className={classes.label}>
            TÍTULO *
          </label>

          <TextField
            id="outlined-multiline-static"
            value={title}
            inputProps={{ maxLength: 100 }}
            onChange={(e) => setTitle(e.target.value)}
            className={classes.title}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => {
              e.preventDefault();
              setIsPaste(true);
            }}
            rows={1}
            disabled={disabled === true && true}
            variant="outlined"
            InputProps={{
              style: {
                padding: "5px",
                fontSize: "14pt",
              },
            }}
          />

          {/* Modificar Aqui */}
          {/* REDACAO */}
          <div className={classes.containerEssay}>
            <label
              htmlFor="outlined-multiline-static"
              className={classes.label}
            >
              REDAÇÃO *
            </label>
            {essayDetails?.status !== 4 && essayDetails?.status !== 6 && (
              <button
                onClick={handleInsertParagraph}
                className={classes.buttonParagraph}
              >
                Inserir Parágrafo
              </button>
            )}
          </div>
          {isPaste && (
            <div className={classes.redText}>
              <small>
                O REDAÇÃO PARANÁ NÃO ACEITA O RECURSO DE COLAGEM DE TEXTOS
              </small>
            </div>
          )}
          <textarea
            id="outlined-multiline-static"
            spellCheck="false"
            autoCapitalize="off"
            autoCorrect="off"
            autoComplete="off"
            value={text}
            ref={inputRef}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onDragOver={handleDragOver}
            onPaste={(e) => {
              e.preventDefault();
              setIsPaste(true);
            }}
            className={isFocused ? classes.focusedTextarea : classes.textarea}
            rows={25}
            disabled={disabled === true && true}
            variant="outlined"
            required={true}
            error={textError}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />

          <div className={classes.wordCount}>
            {essayDetails && essayDetails.minAmountWords > 0 && (
              <Typography
                variant={"subtitle2"}
                className={classes.title}
                style={{ float: "left" }}
              >
                Escreva no mínimo {essayDetails.minAmountWords} e no máximo{" "}
                {essayDetails.maxAmountWords} palavras.
              </Typography>
            )}
            <Typography
              variant={"subtitle2"}
              className={classes.title}
              style={{ float: "right" }}
            >
              {wordCount} PALAVRAS ENCONTRADAS
            </Typography>
          </div>
          {/* {isModalVisible ? (
            <Modal type="success" onClose={() => setIsModalVisible(false)}>
              <div className={classes.modalContent}>
                <b className={classes.modalTitle}>
                  Rascunho salvo com sucesso!
                </b>
              </div>
            </Modal>
            <Toast
              setOpen={setIsModalVisible}
              msg="RASCUNHO SALVO COM SUCESSO!"
              open={isModalVisible}
              severity="success"
              href={null}
            />
          ) : null} */}
          {isModalErrorVisible ? (
            // <Modal type="alert" onClose={() => setIsModalErrorVisible(false)}>
            //   <div className={classes.modalContent}>
            //     <b className={classes.modalTitle}>Atenção!</b>
            //     <p className={classes.modalText}>
            //       Houve um erro ao salvar sua redação. Tente novamente em alguns
            //       instantes.
            //     </p>
            //   </div>
            // </Modal>
            <Toast
              setOpen={setIsModalErrorVisible}
              msg="ERRO AO SALVAR A REDAÇÃO! TENTE NOVAMENTE."
              open={isModalErrorVisible}
              severity="error"
              href={null}
            />
          ) : null}
        </div>

        {/* RIGHT - APOIO */}
        <div className={classes.motivatingTexts}>
          {offline && (
            <>
              <center>
                <div style={{ marginBottom: "25px", color: "#FF0000" }}>
                  Atenção! Seu navegador está enfrentando problemas de conexão.
                </div>
              </center>
            </>
          )}
          {essayDetails && (
            <>
              <div
                className={classes.container}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <center>
                  <h3
                    style={{
                      margin: "15px 0",
                      color: "var(--blue-light)",
                      fontSize: "16pt",
                    }}
                  >
                    REDAÇÃO: {essayDetails.statusDescription.toUpperCase()}
                  </h3>
                </center>
              </div>
              <div className="spacer">
                <br />
                <br />
              </div>
            </>
          )}
          <div>
            <label style={{ display: "none", marginBottom: "20px" }}>
              <input
                type="checkbox"
                checked={autoSave}
                //onChange={(e) => setAutoSave(e.target.checked)}
                style={{ marginRight: "10px" }}
              />
              Salvar automaticamente
            </label>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 15,
            }}
          >
            <h3 style={{ color: "var(--blue-light)", marginTop: 15 }}>
              REDAÇÃO CRIADA POR
            </h3>
            {/* <InfoNote
              tooltipText="Devido a instabilidades, o salvamento automático foi desabilitado. Para salvar sua redação, clique em SALVAR RASCUNHO."
              linkUrl="/About/Info"
            /> */}
          </div>
          <p style={{ textAlign: "justify" }}>
            {essayDetails?.selfCreated ? "Mim" : essayDetails?.creator}
          </p>
          <h3 style={{ color: "var(--blue-light)", marginTop: 15 }}>
            PROPOSTA DE
          </h3>
          <p style={{ textAlign: "justify" }}>{type}</p>
          <h3 style={{ color: "var(--blue-light)", marginTop: 15 }}>
            GÊNERO TEXTUAL
          </h3>
          <p style={{ textAlign: "justify" }}>{genre}</p>
          <h3 style={{ color: "var(--blue-light)", marginTop: 15 }}>TEMA</h3>
          <p style={{ textAlign: "justify" }}>{theme?.title}</p>
          <h3 style={{ color: "var(--blue-light)", marginTop: 15 }}>TOKEN</h3>
          <div className={classes.tokenContainer}>
            <p style={{ textAlign: "justify" }}>
              <b>{props.match.params.token}</b>
            </p>
            <button
              onClick={() => setIsModalOpen(true)}
              className={classes.btnToken}
            >
              Buscar redação por token
            </button>
          </div>

          {/* Modal */}
          {isModalOpen && (
            <Modal
              type=""
              onClose={() => setIsModalOpen(false)}
              onConfirm={handleModalConfirm}
            >
              <div className={classes.modalContent}>
                <b className={classes.modalTitle}>ATENÇÃO!</b>
                <p className={classes.modalText}>
                  Você será redirecionado para a busca de redação por TOKEN.
                  Prossiga somente após SALVAR SEU TEXTO, pois o conteúdo NÃO
                  SALVO SERÁ PERDIDO.
                </p>
              </div>
            </Modal>
          )}
          <h3 style={{ color: "var(--blue-light)", marginTop: 15 }}>
            PROPOSTA
          </h3>
          <div
            variant="subtitle1"
            component="subtitle1"
            className="ql-editor"
            style={{
              textAlign: "justify",
              minHeight: "25rem",
            }}
            dangerouslySetInnerHTML={{
              __html: essayDetails?.details ?? theme?.description,
            }}
          />
          {essayDetails && essayDetails.rules.length > 0 && (
            <>
              <Typography variant={"h6"} style={{ marginTop: 15 }}>
                REGRAS
              </Typography>
              {essayDetails?.rules?.map((rule, index) => (
                <Typography variant="subtitle1">
                  {index + 1} - {rule.description}
                </Typography>
              ))}
            </>
          )}

          <hr
            style={{
              marginTop: 30,
              marginBottom: 30,
              height: 1,
              width: "100%",
            }}
          />
          {motivatingTexts.length > 0 && (
            <>
              <Typography
                variant={"h6"}
                style={{ marginTop: 15, color: "var(--blue-light)" }}
              >
                TEXTOS DE APOIO
              </Typography>
              <div style={{ marginTop: 15, wordWrap: "break-word" }}>
                {motivatingTexts.map((e: any) => (
                  <>
                    {e.isFile && (
                      <>
                        <br />
                        <img
                          alt="Textos de Apoio"
                          style={{
                            display: "block",
                            margin: "auto",
                            width: "100%",
                          }}
                          src={e.text}
                        />
                        <br />
                      </>
                    )}
                    {!e.isFile && (
                      <>
                        <div
                          style={{ textAlign: "justify", textIndent: 50 }}
                          dangerouslySetInnerHTML={{
                            __html: e.text,
                          }}
                        ></div>
                      </>
                    )}
                  </>
                ))}
              </div>
            </>
          )}
          <hr
            style={{
              marginTop: 30,
              marginBottom: 30,
              height: 1,
              width: "100%",
            }}
          />
        </div>
      </div>
      {saveStatus === false && (
        <Toast
          setSaveStatus={setSaveStatus}
          msg="NÃO FOI POSSÍVEL SALVAR SEU RASCUNHO. TENTE NOVAMENTE."
          open={!isLoadingSave}
          severity="error"
          href={null}
        />
      )}
      {saveStatus === true && (
        <Toast
          setSaveStatus={setSaveStatus}
          msg="RASCUNHO SALVO COM SUCESSO!"
          open={!isLoadingSave}
          severity="success"
          href={null}
        />
      )}
      <Loading isLoading={isLoadingSave} txt="SALVANDO" />
      {!disabled && (
        <div
          className={classes.container}
          style={{
            margin: "0% 1% 1% 1%",
            justifyContent: "center",
            display: "flex",
            backgroundColor: "white",
            padding: "3px",
            borderRadius: "0.25rem",
            width: "97vw",
          }}
        >
          <div className={classes.wrapper}>
            <center>
              <button
                style={{
                  background: "white",
                  color: "var(--blue-light)",
                  width: "15rem",
                  height: "3rem",
                  margin: "0.5rem",
                  borderWidth: "1px",
                  borderColor: "var(--blue-light)",
                  borderRadius: "0.25rem",
                  fontWeight: "bold",
                }}
                disabled={wordCount === 0 || saveStatus !== null}
                // disabled={
                //   (disabled === true && true) ||
                //   buttonTextDisabled ||
                //   loading ||
                //   loadingCorrection
                // }
                onClick={saveDraft}
              >
                SALVAR RASCUNHO
                {/* {buttonTitle} */}
                {/* {errorTimer && IsErrorTimer && (
                  <Countdown
                    date={errorTimer}
                    renderer={(props) => (
                      <>
                        {" "}
                        em {props.minutes}:{props.seconds}
                      </>
                    )}
                    onComplete={() => {
                      setButtonDisabled(false);
                      setButtonTextDisabled(false);
                      setIsErrorTimer(false);
                    }}
                  />
                )} */}
              </button>
              {/* {loading && (
                <>
                  
                  <div
                    style={{
                      position: "fixed",
                      display: "absolute",
                      backgroundColor: "var(--background)",
                      zIndex: "50000",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      id="dvLoading"
                      style={{
                        position: "fixed",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "var(--background)",
                      }}
                    >
                      {" "}
                      <center>
                        <img src={loadingLogo} alt="CARREGANDO" width="15%" />
                      </center>
                    </div>
                  </div>
                </>
              )} */}
              <button
                style={{
                  background: "var(--green)",
                  color: "var(--white)",
                  width: "15rem",
                  height: "3rem",
                  margin: "0.7rem",
                  borderWidth: "0",
                  borderRadius: "0.25rem",
                  fontWeight: "bold",
                }}
                disabled={
                  (disabled === true && true) ||
                  loading ||
                  loadingCorrection ||
                  (disableCorrection === true && true) ||
                  buttonDisabled ||
                  offline
                }
                onClick={() => setRun(true)}
              >
                CORRIGIR ONLINE{" "}
                {disableCorrection === true && (
                  <Countdown
                    date={timer}
                    renderer={(props) => (
                      <>
                        {" "}
                        {/* em {props.minutes}:{props.seconds} */}
                        EM {props.seconds}
                      </>
                    )}
                    onComplete={() => setDisableCorrection(false)}
                  />
                )}
              </button>
              {text.length === 0 && (
                <p style={{ padding: "2px 0", color: "var(--red)" }}>
                  {" "}
                  Para salvar seu rascunho ou corrigir sua redação, o campo
                  REDAÇÃO precisa estar preenchido.
                </p>
              )}
              {offline && (
                <p style={{ padding: "2px 0", color: "var(--red)" }}>
                  {" "}
                  Seu navegador está desconectado do servidor. A Correção Online
                  foi desabilitada temporariamente. Certifique que a sua rede
                  está estável.
                </p>
              )}
            </center>
          </div>
        </div>
      )}

      <div
        className={classes.container}
        style={{ alignItems: "center", justifyContent: "center" }}
      ></div>
      {essayDetails && (
        <div>
          {/* <Typography variant={"h6"}> Histórico de correções</Typography>
					{theme && theme.themeTypeId && (
						<>
							<GetGenre callback={(e) => handleGenre(e)} />
							<GetType callback={(e) => handleType(e)} />
						</>
					)} */}

          {/* DETALHES DO REVIEW */}
          <GetReviewByToken
            token={props.match.params.token}
            callback={(e: any) => setReviewDetails(e)}
          />

          {reviewDetails && (
            <>
              <div
                style={{
                  margin: "1% 1% 0% 1%",
                  backgroundColor: "var(--blue-light)",
                  padding: "1%",
                  borderTopLeftRadius: "0.25rem",
                  borderTopRightRadius: "0.25rem",
                }}
              >
                <center>
                  <Typography variant={"h6"} style={{ color: "var(--white)" }}>
                    {" "}
                    DETALHES DA CORREÇÃO
                  </Typography>
                </center>
              </div>

              {reviewDetails && (
                <div
                  style={{
                    margin: "0 1%",
                    border: "1px solid #c1c1c1",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "white",
                  }}
                  className={classes.container}
                >
                  <div
                    className={classes.reviewResultBox}
                    style={{ backgroundColor: "#efefef" }}
                  >
                    <div
                      className={classes.reviewResultDescriptor}
                      style={{ color: "var(--blue-light)" }}
                    >
                      <Typography variant={"body1"} style={{ margin: "auto" }}>
                        <strong>DESCRIÇÃO</strong>
                      </Typography>
                    </div>
                    <div
                      className={classes.reviewResultScore}
                      style={{ color: "var(--blue-light)" }}
                    >
                      <Typography variant={"body1"} style={{ margin: "auto" }}>
                        <strong>NOTA</strong>
                      </Typography>
                    </div>
                  </div>
                  {reviewDetails.result.map((e: any) => (
                    <div
                      className={classes.reviewResultBox}
                      style={{ color: "var(--blue-dark)" }}
                    >
                      <div className={classes.reviewResultDescriptor}>
                        <Typography variant={"body1"}>
                          <strong>{e.category.toUpperCase()}</strong>:{" "}
                          {e.criterion}
                        </Typography>
                        {e.criterionOption && (
                          <Typography
                            variant={"body1"}
                            style={{ marginTop: 10 }}
                          >
                            <strong>MOTIVO DA NOTA</strong>: {e.criterionOption}
                          </Typography>
                        )}
                      </div>
                      <div className={classes.reviewResultScore}>
                        <Typography
                          variant={"body1"}
                          style={{ margin: "auto" }}
                        >
                          {e.score}/{e.maxScore}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div
                style={{
                  margin: "-2px 1%",
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid #c1c1c1",
                  borderBottomLeftRadius: "0.25rem",
                  borderBottomRightRadius: "0.25rem",
                  backgroundColor: "white",
                }}
                className={classes.container}
              >
                <Typography
                  variant={"body1"}
                  style={{ color: "var(--blue-dark)" }}
                >
                  <strong>DEVOLUTIVA DO PROFESSOR</strong>
                  {": "}
                  {reviewDetails.reviewText === ""
                    ? "\t Não há comentários a serem exibidos."
                    : reviewDetails.reviewText}
                </Typography>
              </div>
            </>
          )}

          {/* HISTÓRICO DE CORREÇÕES */}
          {essayDetails && (
            <CorrectionHistory
              essayId={essayDetails.id}
              token={props.match.params.token}
            />
          )}

          {/* ########################### RESULTADO #################################### */}
          <Dialog
            open={open}
            onClose={() => toggleOpen(false)}
            aria-labelledby="form-dialog-title"
            fullScreen
          >
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  backgroundColor: "var(--blue-light)",
                  color: "var(--white)",
                }}
              >
                <center>
                  <DialogTitle id="form-dialog-title">
                    <h1 style={{ textTransform: "uppercase" }}>
                      Correção online
                    </h1>
                  </DialogTitle>
                </center>
              </div>
              <DialogContent style={{ overflowY: "unset" }}>
                <div className={classes.resultTextArea}>
                  <div
                    className={classes.resultScore}
                    style={{ color: "var(--blue-light)" }}
                  >
                    <h6>
                      PONTUAÇÃO ESTIMADA: {score > 40 ? score * 0.4 : score}/ 40
                    </h6>
                  </div>
                  <hr
                    style={{
                      border: "solid 0.1px #d8d8d8",
                      width: "100%",
                      marginBottom: "1%",
                    }}
                  />

                  <ErrorList json={json} />
                  <hr
                    style={{
                      border: "solid 0.1px #d8d8d8",
                      width: "100%",
                      marginTop: "1%",
                    }}
                  />
                </div>
                <div
                  className={classes.container}
                  style={{ paddingBottom: "10px" }}
                >
                  <div className={classes.resultTextArea}>
                    <Typography className={classes.resultText}>
                      <Correction
                        json={json}
                        fullText={title + "\n" + text}
                        text={text}
                        title={title}
                      />
                    </Typography>
                  </div>
                  <div
                    className={classes.resultTextArea}
                    onContextMenu={(e) => e.preventDefault()}
                    style={{ marginBottom: "10px" }}
                  >
                    {offline && (
                      <>
                        <center>
                          <div
                            style={{
                              color: "var(--red)",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}
                          >
                            Atenção! Seu navegador está enfrentando problemas de
                            conexão.
                          </div>
                        </center>
                      </>
                    )}
                    <label className={classes.label}>TOKEN</label>
                    <div className={classes.tokenContainer}>
                      <p
                        style={{
                          textAlign: "justify",
                          marginLeft: "10px",
                          fontSize: "14pt",
                        }}
                      >
                        <b>{props.match.params.token}</b>
                      </p>
                    </div>
                    <label
                      htmlFor="outlined-multiline-static"
                      className={classes.label}
                    >
                      TÍTULO *
                    </label>
                    <TextField
                      id="outlined-multiline-static"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className={classes.title}
                      onCopy={(e) => e.preventDefault()}
                      onPaste={(e) => {
                        e.preventDefault();
                        setIsPaste(true);
                      }}
                      rows={1}
                      disabled={disabled === true && true}
                      variant="outlined"
                    />
                    <div className={classes.containerEssay}>
                      <label
                        htmlFor="outlined-multiline-static"
                        className={classes.label}
                      >
                        REDAÇÃO *
                      </label>
                      {essayDetails?.status !== 4 &&
                        essayDetails?.status !== 6 && (
                          <button
                            onClick={handleInsertParagraph}
                            className={classes.buttonParagraph}
                          >
                            Inserir Parágrafo
                          </button>
                        )}
                    </div>
                    {isPaste && (
                      <div className={classes.redText}>
                        <small>
                          O REDAÇÃO PARANÁ NÃO ACEITA O RECURSO DE COLAGEM DE
                          TEXTOS
                        </small>
                      </div>
                    )}
                    <textarea
                      id="outlined-multiline-static"
                      spellCheck="false"
                      autoCorrect="off"
                      autoCapitalize="off"
                      autoComplete="off"
                      multiline
                      value={text}
                      ref={inputRef}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      onDragOver={handleDragOver}
                      onPaste={(e) => {
                        e.preventDefault();
                        setIsPaste(true);
                      }}
                      className={
                        isFocused ? classes.focusedTextarea : classes.textarea
                      }
                      rows={25}
                      disabled={disabled === true && true}
                      variant="outlined"
                      required={true}
                      error={textError}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                    />
                    <div className={classes.wordCount}>
                      {essayDetails && essayDetails.minAmountWords > 0 && (
                        <Typography
                          variant={"subtitle2"}
                          className={classes.title}
                          style={{ float: "left" }}
                        >
                          Escreva no mínimo {essayDetails.minAmountWords} e no
                          máximo {essayDetails.maxAmountWords} palavras.
                        </Typography>
                      )}
                      <Typography
                        variant={"subtitle2"}
                        className={classes.title}
                        style={{ float: "right" }}
                      >
                        {wordCount} palavras encontradas
                      </Typography>
                    </div>
                  </div>
                </div>
                {essayDetails.selfCreated && (
                  <center>
                    <h4
                      style={{
                        margin: "2%",
                        color: "var(--blue-light)",
                        textTransform: "uppercase",
                      }}
                    >
                      ATENÇÃO! Essa é uma redação de treino, ela não será
                      enviada para nenhum professor.
                    </h4>
                  </center>
                )}

                <div
                  className={classes.container}
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <button
                    style={{
                      background: "var(--blue-light)",
                      color: "var(--white)",
                      borderRadius: "0.25rem",
                      width: "15rem",
                      height: "3rem",
                      margin: "0.25rem",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                    color="secondary"
                    onClick={() => {
                      toggleOpen(false);
                      setIsCorrecao(false);
                    }}
                    disabled={offline}
                  >
                    Voltar para a redação
                  </button>

                  {essayDetails.selfCreated ? (
                    <button
                      style={{
                        background: "var(--green)",
                        color: "var(--white)",
                        borderRadius: "0.25rem",
                        width: "15rem",
                        height: "3rem",
                        margin: "0.25rem",
                        borderWidth: "0",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                      onClick={() => handleSend()}
                      disabled={
                        wordCount < essayDetails.minAmountWords || offline
                      }
                    >
                      Concluir
                    </button>
                  ) : (
                    <button
                      style={{
                        background: "var(--green)",
                        color: "var(--white)",
                        borderRadius: "0.25rem",
                        width: "15rem",
                        height: "3rem",
                        margin: "0.5rem",
                        borderWidth: "0",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                      onClick={() => handleSend()}
                      disabled={
                        wordCount < essayDetails.minAmountWords || offline
                      }
                    >
                      Enviar Redação
                    </button>
                  )}
                  {offline && (
                    <p
                      style={{
                        color: "var(--red)",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Seu navegador está desconectado do servidor. A função de
                      enviar redação foi desabilitada temporariamente.
                      Certifique que a sua rede está estável.
                    </p>
                  )}
                </div>
                <div style={{ marginTop: "3%" }}>
                  {wordCount < essayDetails.minAmountWords && !offline ? (
                    <>
                      <center>
                        <p
                          style={{
                            color: "var(--red)",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          }}
                        >
                          O envio será liberado após o mínimo de palavras ser
                          atingido.
                        </p>
                      </center>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* {paragraphCount < 3 ? (
                    <>
                      <center>
                        <p
                          style={{
                            color: "var(--red)",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          }}
                        >
                          O envio será liberado após o mínimo de parágrafos
                          serem atingidos.
                        </p>
                      </center>
                    </>
                  ) : (
                    <></>
                  )} */}
                </div>
                <div style={{ width: "100%", margin: 10 }}>
                  {essayDetails &&
                    essayDetails.selfCreated === false &&
                    descriptores && (
                      <>
                        <div
                          style={{
                            backgroundColor: "var(--blue-light)",
                            color: "var(--white)",
                            textTransform: "uppercase",
                            padding: "1%",
                            borderTopLeftRadius: "0.25rem",
                            borderTopRightRadius: "0.25rem",
                            marginTop: "3%",
                          }}
                        >
                          <center>
                            <h3>
                              Além da correção automática, você será avaliado
                              pelo seu professor nos seguintes critérios:
                            </h3>
                          </center>
                        </div>
                        {descriptores.map((e: any) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: 10,
                              minHeight: "30px",
                              border: "0.5px solid var(--background)",
                              color: "var(--blue-dark)",
                            }}
                          >
                            <div
                              style={{
                                width: "85%",
                                whiteSpace: "break-spaces",
                                marginLeft: 10,
                              }}
                            >
                              {e.description}
                            </div>
                            <div
                              style={{
                                width: "15%",
                                textAlign: "center",
                                margin: "auto",
                                fontWeight: "bold",
                              }}
                            >
                              <b>
                                NOTA MÁXIMA<br></br> <h2>{e.maxScore}</h2>
                              </b>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                </div>
                {disabled === true && (
                  <p>Não é possível realizar alterações nessa redação.</p>
                )}
                <Dialog
                  open={openDialog}
                  onClose={() => toggleOpen(false)}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <div style={{ backgroundColor: "var(--blue-light)" }}>
                    <center>
                      <DialogTitle
                        id="alert-dialog-title"
                        style={{
                          color: "var(--white)",
                          textTransform: "uppercase",
                        }}
                      >
                        {"Deseja realmente concluir a redação?"}
                      </DialogTitle>
                    </center>
                  </div>
                  <DialogContent>
                    <DialogContentText
                      id="alert-dialog-slide-description"
                      style={{
                        color: "var(--blue-light)",
                        textAlign: "justify",
                        margin: "1%",
                      }}
                    >
                      {json?.matches?.length
                        ? "Ainda existem " +
                          json?.matches?.length +
                          " erros. Deseja enviar mesmo assim? \n" +
                          "Ao enviar a redação para o professor não será possível realizar alterações."
                        : "Ao enviar a redação para o professor não será possível realizar alterações."}
                    </DialogContentText>
                  </DialogContent>
                  <div>
                    <DialogActions
                      style={{
                        alignContent: "center",
                        textAlign: "center",
                        float: "right",
                        margin: "1%",
                      }}
                    >
                      <button
                        onClick={() => {
                          if (json?.matches?.length) {
                            handleDialogClose();
                            //toggleOpen(false);
                          } else handleDialogClose();
                        }}
                        color="primary"
                        disabled={loadingCorrection}
                        style={{
                          float: "right",
                          marginRight: "2%",
                          width: "10rem",
                          height: "2rem",
                          fontWeight: "bold",
                          color: "var(--blue-dark)",
                          borderColor: "var(--blue-dark)",
                          backgroundColor: "var(--white)",
                          borderWidth: "1px",
                        }}
                      >
                        {json?.matches?.length ? "CORRIGIR" : "CANCELAR"}
                      </button>
                      <div className={classes.wrapper}>
                        {essayDetails.selfCreated ? (
                          <button
                            onClick={() => handleDialogConfirm()}
                            disabled={loadingCorrection}
                            style={{
                              float: "right",
                              width: "10rem",
                              border: 0,
                              height: "2rem",
                              color: "var(--white)",
                              background: "var(--green)",
                              fontWeight: "bold",
                            }}
                          >
                            CONCLUIR
                          </button>
                        ) : (
                          <button
                            onClick={() => handleDialogConfirm()}
                            disabled={loadingCorrection}
                            style={{
                              float: "right",
                              margin: "1rem",
                              width: "10rem",
                              border: 0,
                              height: "2rem",
                              color: "var(--white)",
                              background: "var(--green)",
                              fontWeight: "bold",
                            }}
                          >
                            ENVIAR
                          </button>
                        )}
                        {loadingCorrection && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </DialogActions>
                  </div>
                </Dialog>
              </DialogContent>
            </form>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default Essay;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minHeight: "100%",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "column",
      minHeight: "100%",
      margin: "auto",
    },
  },

  buttonSuccess: {
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  wrapper: {
    margin: "0 auto",
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  inputArea: {
    [theme.breakpoints.down("md")]: {
      order: 2,
      minWidth: 0,
      minHeight: 0,
      width: "100%",
      margin: "20px auto",
      maxHeight: "none",
    },
    background: "var(--white)",
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    minHeight: "35%",
    padding: "2rem",
    width: "50%",
    margin: "1rem",
  },

  motivatingTexts: {
    [theme.breakpoints.down("md")]: {
      order: 1,
      minHeight: 300,
      width: "100%",
      margin: "-25px auto",
      fontSize: "14pt",
    },
    background: "var(--white)",
    display: "flex",
    flexDirection: "column",
    maxHeight: "1000px",
    border: "none",
    borderRadius: "0.25rem",
    margin: "1% 1% 1% 0%",
    padding: "3rem",
    overflowY: "scroll",
    overflowX: "hidden",
    alignSelf: "flex-start",
    width: "50%",
  },
  reviewResultDescriptor: {
    width: "85%",
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid lightgray",
  },

  reviewResultScore: {
    width: "15%",
    display: "flex",
  },
  reviewResultBox: {
    padding: 20,
    display: "flex",
    borderBottom: "1px solid #c1c1c1",
    [theme.breakpoints.down("md")]: {
      padding: 10,
    },
  },

  wordCount: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    color: "gray",
    marginLeft: 10,
    marginRight: 10,
    border: "1px  solid lightgray",
    borderRadius: "0 0.25rem",
    padding: "10px",
  },
  root: {
    display: "flex",
    height: "50%",
    margin: "1%",
  },
  topDialog: {
    display: "flex",
    flexDirection: "column",
    height: "60%",
    maxHeight: "600px",
    margin: theme.spacing(2),
    overflow: "scroll",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  sidebar: {
    margin: "1%",
  },
  vertical: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    display: "flex",
    margin: "10px",
    minWidth: "200px",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  containerEssay: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  textarea: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    whiteSpace: "pre-wrap",
    marginBottom: -1,
    minHeight: "500px",
    borderRadius: "0.28rem",
    boxSizing: "border-box",
    padding: "15px",
    fontSize: "16px",
    lineHeight: "1.5",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  focusedTextarea: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    whiteSpace: "pre-wrap",
    marginBottom: -1,
    minHeight: "500px",
    boxSizing: "border-box",
    padding: "15px",
    fontSize: "16px",
    lineHeight: "1.5",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    outline: "none",
    border: "2px solid var(--blue-light)",
    borderRadius: "0.28rem",
  },
  title: {
    margin: "10px",
  },
  label: {
    margin: "10px",
    color: "var(--blue-light)",
    fontWeight: "bold",
  },
  buttonParagraph: {
    backgroundColor: "var(--blue-light)",
    marginRight: "10px",
    color: "var(--white)",
    textTransform: "uppercase",
    minWidth: "15vw",
    maxWidth: "30vw",
    padding: "5px 10px",
    //display: "none",
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  typography: {
    minHeight: "250px",
    justifyContent: "right",
    alignItems: "center",
  },
  resultText: {
    minHeight: 300,
    padding: 20,
  },
  resultTextArea: {
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
    },
    textDecoration: "none",
    display: "flex",
    border: " 1px solid #bfbfbf",
    borderRadius: "0.25rem",
    flexDirection: "column",
    width: "100%",
    margin: "0.5rem",
  },

  resultScore: {
    textAlign: "center",
    margin: "0.5rem",
  },
  btnToken: {
    textDecoration: "underline",
    color: "var(--blue-light)",
    cursor: "pointer",
    border: "none",
    background: "none",
  },
  tokenContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalTitle: {
    fontSize: "1rem",
  },
  modalText: {
    textAlign: "center",
  },
  redText: {
    color: "red",
    marginLeft: "10px",
    marginTop: "5px",
  },
  modalToken: {
    fontSize: "14pt",
  },
}));
