import styled from "styled-components";

interface Props {
  active: boolean;
}

export const Container = styled.nav<Props>`

        background:var(--blue-light);
        height: 5rem;
        color: var(--white);
        position: sticky;
        top: 0;
        z-index: 999;

        .Button{
                width: 10rem;
                background: var(--blue-light);
                flex-direction: row;
                display: flex;
                width: 95%;
                align-items: center;

                color: var(--white);    
                font-size: 1rem;
                font-weight: bold;
                
                border-radius: 0.25rem;
                border-color: var(--background);
                border-width: 0.15rem;
                border-style: solid;
        }
        img{
                margin: 0.75rem 0.75rem;
                cursor: pointer;
                &:hover{
                        filter: brightness(1.2);
                }
        }

        div{
                position: relative;
                float: right;
                text-align: center;
                line-height:100%;
                margin: auto;

                button{
                        height: 3rem;
                        margin: 0.75rem 0.75rem;
                        border-width: 0;
                        font-size:1rem;
                        font-weight: 900;

                        &:hover{
                        filter: brightness(1.2);
                        }
                }
        }
        span{
                margin: auto;
        }
        
        .avatarImg{
                margin: 0.75rem 0.75rem;
                border-radius: 4rem;
                height: 3rem;
        }
        
        .avatarImg1{
                border-radius: 4rem;
                height: 3rem;
        }
        
        .ddName:hover{
                background-color: #0B3249; 
        }

        .ddIcon{
                height: 0.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 1.3rem;
        }
       
        .ddIconItem{
                height: 1rem;
                margin-right: 5%;
        }

        .dropdownItem{
                cursor: pointer;
                flex-direction: row;
                display: flex;
                width: 100%;
                align-items: center;

        }

        .dropdownItem p{
                text-align: right;
                text-transform: uppercase;
                right: 0;
                float: right;
        }

        
         
        
        .dropdownMenu{
                background-color: var(--blue-light);
                font-weight: bold;
                border-bottom-left-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
                z-index: 1001;
                position: absolute;
                margin-top: 4.5rem;
                width: 100%;
                white-space: nowrap;
                overflow:hidden;
        }
        
        hr{
                width: 100%;
                height: 1px;
                border: 0px;
                border-top: 1px solid #0B3249;
                background-color: #0B3249;  
        }

        @media screen and (min-width: 901px) {
                #logo {
                    float: left;
                    margin-left: 1.5%;
                }

                #spacer{
                    background-color: var(--background);
                    width: 100%;
                    height: 10px;
                }

                .avatarImg1{
                        display:none;
                }

                .dropdownItem:hover{
                        background-color: #0B3249;
                        margin-right: -5%;
                        margin-left: -5%;
                }      
        }

        @media(max-width: 900px){
                flex-direction: column;
                align-items: flex-start;
                        ${(props) =>
                          props.active &&
                          `
                        position: fixed; 
                        top: 0;
                        left: 0;
                        height: 100% !important;
                `};
                z-index:99;
                height: 5rem;
                
                div{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin: 0 auto;
                        text-align: center;
                }

                img{
                        display: flex;
                        margin: 0 auto;
                        ${(props) => props.active && `margin: 3rem`}
 
                }

                span{
                        ${(props) => props.active && `display: block`}
                        ${(props) => !props.active && `display: none`}
                }

                .avatarImg1{
                        ${(props) =>
                          props.active &&
                          `display: flex; max-width: 3rem; margin: 0 auto;`}
                        ${(props) => !props.active && `display: none`}
                }

                .avatarImg{
                        display:none;
                }

                .logoutButton{
                        color: var(--red);
                        background: var(--blue-light);
                        width: 95%;
                }
                
                .ddIcon{
                        display:none;
                }

                .ddName{
                        margin-bottom: 3rem;
                        margin-top: 1.5rem;
                        font-size: 12pt;
                }
                #logo {
                        padding-top: 0.8rem;
                }
        }      
}
`;

export const ToggleButton = styled.nav`
  position: fixed;
  top: 1.5rem;
  left: 1.3rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;

  .bar {
    height: 3px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    display: flex !important;
  }

  @media (max-width: 900px) {
    display: flex;
  }
`;
